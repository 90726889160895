import React, { useEffect, useState } from 'react'
import { DepartmentNS, Department } from 'services/departments'
import { DataGrid, GridColumns, GridRowModel, GridRowModes, GridRowModesModel, GridActionsCellItem, GridRowId, GridEventListener, GridRowParams, MuiEvent, GridRowsProp } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Modal, TextField } from '@mui/material'


const Departments = () => {
	const [departments, setDepartments] = useState<GridRowsProp<Department>>([])
	const [open, setOpen] = useState(false)
	const [valueName, setValueName] = useState('')
	const [columns, setColumns] = useState<GridColumns>([])
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

	useEffect(() => {
		DepartmentNS.list(departments => setDepartments(departments))
	}, [])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>,
	) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}

	const handleDeleteClick = (id: GridRowId) => () => {
		if (typeof id === 'number') return
		if (confirm('Deseja excluir esse departamento mesmo?')) {
			DepartmentNS.remove(id).then(() => alert('Departamento excluído.'))
		}
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
		  ...rowModesModel,
		  [id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}

	useEffect(() => {
		setColumns([
			{
				field: 'name',
				headerName: 'Departamento',
				width: 250,
				editable: true,
			}, {
				field: 'actions',
				type: 'actions',
				headerName: 'Ações',
				width: 100,
				cellClassName: 'actions',
				getActions: ({ id }) => {
					const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
					if (isInEditMode) {
						return [
							<GridActionsCellItem
								icon={<SaveIcon />}
								label='Save'
								onClick={handleSaveClick(id)}
							/>,
							<GridActionsCellItem
								icon={<CancelIcon />}
								label='Cancel'
								className='textPrimary'
								onClick={handleCancelClick(id)}
								color='inherit'
							/>,
						]
					}

					return [
						<GridActionsCellItem
						  icon={<EditIcon />}
						  label='Edit'
						  className='textPrimary'
						  onClick={handleEditClick(id)}
						  color='inherit'
					  />,
					  <GridActionsCellItem
						  icon={<DeleteIcon />}
						  label='Delete'
						  onClick={handleDeleteClick(id)}
						  color='inherit'
						/>,
				  	]
				},
			},
		])
	}, [rowModesModel])

	function handleSubmit() {
		if (valueName.length === 0) {
			alert('Preencha esse campo corretamente.')
			return ''
		}
		const values = {
			name: valueName,
		}

		DepartmentNS.create(values).then(() => {
			setOpen(false)
			alert('Criado com sucesso')
			setValueName('')
		}).catch(err => alert('Erro ao criar um departamento, contate o TI' + err))
	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		setValueName(event.target.value)
	}

	const processRowUpdate = async (newRow: GridRowModel<Department>) => {
		await DepartmentNS.update(newRow.id, newRow)
		return newRow
	}

	return (
		<div>
			<div className='mb-8'>
				<Button onClick={() => setOpen(true)} variant='contained'>+ Adicionar</Button>
			</div>
			<div className='w-full h-[700px]'>
				<DataGrid
					rows={departments}
					columns={columns}
					editMode='row'
					rowModesModel={rowModesModel}
					onRowEditStart={handleRowEditStart}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={error => console.log(error)}
					experimentalFeatures={{ newEditingApi: true }}
				/>
        	</div>
			<Modal open={open} onClose={() => setOpen(false)} >
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
					}}
				>
					<TextField sx={{ width: '100%', marginBottom: '1rem' }} value={valueName} label='Departamento' onChange={handleChange} />
					<Button className='w-full h-12' variant='contained' onClick={handleSubmit} >Adicionar</Button>
				</Box>
			</Modal>
		</div>

	)
}

export default Departments