import Draw from 'assets/Politicas.png'
import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Dialog } from '@mui/material'
import PageviewIcon from '@mui/icons-material/Pageview'
import { getDownload, getPolitics } from 'services/documents'
import { AiOutlineSearch } from 'react-icons/ai'
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage'
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore'

const Policy = () => {
	const [open, setOpen] = useState(false)
	const [openSecondary, setOpenSecondary] = useState(false)
	const [pdfs, setPdfs] = useState<{url: string, name: string}[]>([])
	const [current, setCurrent] = useState('')
	const storage = getStorage()
	const db = getFirestore()

	useEffect(() => {
		getDocs(query(collection(db, 'documents'), where('type', '==', 'policy'))).then(querySnap => {

			Promise.all(querySnap.docs.map(async (doc) => {
				const name = doc.get('name')
				const url = await getDownloadURL(ref(storage, `documents/${doc.id}.pdf`))
				return { name, url }
			})).then(pdfs => setPdfs(pdfs))
		})

	}, [])
	return (
		<div className='grid grid-cols-1 lg:grid-cols-2'>
			<div className='lg:min-h-screen lg:flex: items-center p-8 sm:p-12'>
				<div className='flex-grow'>
					<h1 className='mt-4 text-blue-900 font-bold text-left text-2xl sm:text-5xl mb-2'>
						Políticas
					</h1>
					<p className='text-left text-blue-900 sm:text-lg'>
						Conheça um pouco mais das nossas Politicas internas e recursos humanos!
					</p>
				</div>
				{pdfs.length === 0 ? <CircularProgress /> :
					<button className='mt-8 bg-white hover:bg-gray-100 text-gray-800 font-bold inline-flex items-center border border-gray-400 rounded-xl'>
						<div className='py-2 px-4' onClick={() => setOpen(true)}>
							Políticas
						</div>
					</button>
				}

				<Dialog
					fullWidth={true}
					maxWidth='xl'
					onClose={() => setOpen(false)} open={open}>
					<div className='p-10'>
						<div className='text-lg font-bold'>Selecione o PDF</div>
						<div className='grid grid-cols-3 gap-4'>
							{pdfs.map(pdf => (
								<div key={pdf.name} className='p-4 rounded-lg border border-black hover:bg-gray-100 cursor-pointer' onClick={e => {
									setOpen(false)
									setOpenSecondary(true)
									setCurrent(pdf.url)
								}}><AiOutlineSearch />{pdf.name}</div>

							))}
						</div>
					</div>
				</Dialog>
				<Dialog
					fullWidth={true}
					maxWidth='xl'
					onClose={() => setOpenSecondary(false)} open={openSecondary}>
					{current === ''
						? <CircularProgress />
						: <embed type='application/pdf' src={current} width='100%' height='1000'/>
					}
				</Dialog>
			</div>
			<div className='lg:min-h-screen lg:flex lg:items-center'>
				<img src={Draw} alt='Desenho' />
			</div>
		</div>
	)
}

export default Policy
