import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentSnapshot, getDoc, onSnapshot, PartialWithFieldValue, query, setDoc } from 'firebase/firestore'
import { firestore } from 'shared/firebase'


export interface FirestoreModerations {
	name: string
}
export interface Moderation {
    id: string
    name: string
}

export const moderationCollection = collection(firestore, 'moderations') as CollectionReference<FirestoreModerations>

function fromFirestore(docSnap: DocumentSnapshot<FirestoreModerations>): Moderation {
	const data = docSnap.data()
	if (data) {
		return {
			...data,
            id: docSnap.id,
		}
	} else {
		throw new Error('Can\'t find id')
	}
}

function toFirestore(post: Partial<Moderation>): PartialWithFieldValue<FirestoreModerations> {
	return { ...post }
}

export namespace ModerationNS {
    export function create(values: Partial<Moderation>) {
    	return addDoc(moderationCollection, toFirestore(values))
    }

    export async function getSync(id: string) {
    	const docSnap = await getDoc(doc(moderationCollection, id))
    	return fromFirestore(docSnap)
    }

    export function get(id: string, cb: (data: Moderation) => void) {
    	return onSnapshot(doc(moderationCollection, id), docSnap => {
    		cb(fromFirestore(docSnap))
    	})
    }
    export function list(cb: (data: Moderation[]) => void) {
    	return onSnapshot(query(moderationCollection), snapshot => {
    		cb(snapshot.docs.map(docSnap => fromFirestore(docSnap)))
    	})
    }
    export function update(id: string, data: Partial<Moderation>) {
    	return setDoc(doc(moderationCollection, id), toFirestore(data), { merge: true })
    }
	export function remove(id: string) {
		return deleteDoc(doc(moderationCollection, id))
	}
}