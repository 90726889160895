import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  Link,
} from "react-router-dom";

import { useAuth } from "shared/auth";
import Home from "pages/main/home";
import Header from "./Header";
import Benefits from "pages/main/benefits";
import ListPost from "pages/admin/posts/List";
import EditPost from "pages/admin/posts/Edit";
import Posts from "pages/main/posts";
import Departments from "pages/admin/departments";
import Moderations from "pages/admin/moderation";
import AddNewPost from "pages/admin/posts/AddNew";
import Profile from "pages/user/Profile";
import ScheduleShow from "pages/user/Timelog";
import Timelog from "pages/admin/fiscal/timelog";
import Contacts from "pages/main/contacts";
import AdminSchedule from "pages/admin/schedule";
import Schedule from "pages/user/Schedule";
import Documents from "pages/admin/documents";
import Clients from "pages/admin/fiscal/clients";
import Footer from "./Footer";
import Login from "pages/login/Login";
import Forget from "pages/login/Forget";
import Users from "pages/admin/users";
import Politicas from "pages/main/policies";
import Manual from "pages/main/manual";
import RPS from "pages/main/tools/RPS";
import Tools from "pages/main/tools";
import ExpandMenu from "components/ExpandMenu";
import NossosValores from "pages/main/nossos-valores";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";

import "../assets/animation.css";
import Sites from "pages/main/sites";

const UnauthenticatedRoutes = () => {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/" /> : <Outlet />;
};

const AuthenticatedRoutes = () => {
  const { currentUser } = useAuth();

  return currentUser ? <Outlet /> : <Navigate to="/login" />;
};

const BaseLayout = () => {
  return (
    <>
      <Header />
      <div className="w-full min-h-screen">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

// const HomeLayout = () => {
// 	return (
// 		<>
// 			<HomeHeader />
// 			<div className='w-full p-10 min-h-screen'>
// 				<Outlet />
// 			</div>
// 			<Footer />
// 		</>
// 	)
// }

const AdminLayout = () => {
  const { currentUser } = useAuth();
  return currentUser ? (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex items-stretch h-full">
        <AdminSidebar />
        <div className="overflow-x-auto w-full pt-4 px-4">
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};
const AdminSidebar = () => {
  const [menuSidebar, setMenuSidebar] = useState(true);
  return (
    <>
      <div className="flex flex-col">
        {menuSidebar && (
          <>
            <BsFillArrowLeftCircleFill
              className="text-blue-iest text-2xl ml-2 mt-2 cursor-pointer hover:text-iest-accent "
              onClick={() => setMenuSidebar(!menuSidebar)}
            />
            <div className="mr-8 flex flex-col space-y-4 px-6 py-3 w-56 z-10 text-blue-iest text-lg font-semibold menu-sidebar-animation ">
              <Link className="hover:bg-gray-300 rounded" to="posts">
                Comunicados
              </Link>
              <Link className="hover:bg-gray-300 rounded" to="arquivos">
                Arquivos
              </Link>
              <Link className="hover:bg-gray-300 rounded" to="departamentos">
                Departamentos
              </Link>
              <Link className="hover:bg-gray-300 rounded" to="moderacao">
                Equipes Moderação
              </Link>
              <Link className="hover:bg-gray-300 rounded" to="users">
                Usuários
              </Link>
              <Link className="hover:bg-gray-300 rounded" to="escala">
                Escala
              </Link>
              <ExpandMenu title="Fiscal">
                <Link
                  className="hover:bg-gray-300 rounded"
                  to="fiscal/apontamento"
                >
                  Apontamento
                </Link>
                <Link
                  className="hover:bg-gray-300 rounded"
                  to="fiscal/clientes"
                >
                  Clientes
                </Link>
              </ExpandMenu>
            </div>
          </>
        )}
        {!menuSidebar && (
          <BsFillArrowRightCircleFill
            className="text-blue-iest text-2xl ml-2 mt-2 cursor-pointer hover:text-iest-accent"
            onClick={() => setMenuSidebar(!menuSidebar)}
          />
        )}
      </div>
    </>
  );
};

const AppRoutes = () => {
  const { currentUser } = useAuth();

  const hasFullAccess = currentUser?.admin || currentUser?.department === "TI";
  const canSeeModerationAdmin =
    (currentUser?.department === "Moderação de Conteúdo" &&
      currentUser.moderationTeam === "ADM") ||
    hasFullAccess;
  const canSeeTimelogAdmin =
    currentUser?.fullName === "Nelson Svanci" ||
    currentUser?.fullName === "Samuel da Silva Camargo" ||
    hasFullAccess;
  const canSeeConverter =
    currentUser?.department === "Fiscal" ||
    currentUser?.department === "DP" ||
    hasFullAccess;

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<UnauthenticatedRoutes />}>
          <Route path="/esqueci" element={<Forget />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
        <Route element={<AuthenticatedRoutes />}>
          <Route element={<BaseLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="beneficios" element={<Benefits />} />
            <Route path="sites" element={<Sites />} />
            <Route path="politicas" element={<Politicas />} />
            <Route path="manual" element={<Manual />} />
            <Route path="profile" element={<Profile />} />
            <Route path="contatos" element={<Contacts />} />
            <Route path="apontamento" element={<ScheduleShow />} />
            <Route path="comunicados" element={<Posts />} />
            <Route path="departamentos" element={<Departments />} />
            <Route path="moderacao" element={<Moderations />} />
            <Route path="escala" element={<Schedule />} />
            <Route path="nossos-valores" element={<NossosValores />} />
            <Route path="ferramentas">
              <Route index element={<Tools />} />
              {canSeeConverter && <Route path="conversor" element={<RPS />} />}
            </Route>

            {/* canSeeConverter &&
						<Route path='conversor' element={<RPS />}>
							<Route path='alipay' element={<Alipay />}/>
							<Route path='cainiao' element={<Cainiao />}/>
							<Route path='shein' element={<Shein />}/>
						</Route>
                        */}
          </Route>
          <Route path="admin" element={<AdminLayout />}>
            {hasFullAccess && (
              <>
                <Route path="posts">
                  <Route index element={<ListPost />} />
                  <Route path="adicionar" element={<AddNewPost />} />
                  <Route path=":id" element={<EditPost />} />
                </Route>
                <Route path="arquivos" element={<Documents />} />
                <Route path="departamentos" element={<Departments />} />
                <Route path="moderacao" element={<Moderations />} />
                {/* <Route path='users/add' element={<AddUser />} /> */}
              </>
            )}
            {canSeeModerationAdmin && (
              <>
                <Route path="users" element={<Users />} />
                <Route path="escala" element={<AdminSchedule />} />
              </>
            )}
            {canSeeTimelogAdmin && (
              <Route path="fiscal">
                <Route path="apontamento" element={<Timelog />} />
                <Route path="clientes" element={<Clients />} />
              </Route>
            )}
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
