import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdEmail, MdLock } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Img from 'assets/login_img.svg'
import Lg from 'assets/iest_logo.png'
import { browserLocalPersistence, browserSessionPersistence, getAuth, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword } from 'firebase/auth'
import { authApp } from 'shared/firebase'
import { Field, Form, Formik, FormikHelpers, useFormik } from 'formik'
// import { TextField } from 'formik-mui'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import * as Yup from 'yup'
import Logo from 'assets/iest_logo-Branco.png'
import { auth } from 'shared/firebase'

interface Values {
	email: string
}

export default function Forget() {

	const forgetSchema = Yup.object().shape({
		email: Yup.string().email('Email inválido').required('Obrigatório'),
	})
	const formik = useFormik({
	  	initialValues: {
			email: '',
		},
		validationSchema: forgetSchema,
		onSubmit: (values: Values, { setSubmitting }) => {
			setSubmitting(true)
			sendPasswordResetEmail(auth, values.email).then(() => {

				alert('Enviamos ao seu email um link para redefinir a sua senha, verifique também a caixa de spam')
				setSubmitting(false)
			})
		},
	})

	return (
		<div className='h-screen w-full bg-slate-400 px-4'>
			<div className='w-32 mx-auto py-10'>
				<img className='w-32' src={Logo}/>
			</div>
			<div className='w-full  mx-auto my-auto bg-white rounded-[100px] shadow-lg py-28 px-4 flex flex-col items-center lg:w-1/2'>
				<div className='text-[#2699FB] font-bold text-3xl mb-4 w-full text-center'>Esqueci a senha</div>
				<div className='text-sm mb-8'>Digite o seu email</div>
				<form onSubmit={formik.handleSubmit} className='flex flex-col w-full'>
					<div className='mb-4 w-full'>
						<TextField
							fullWidth
							label='Email'
							variant='standard'
							name='email'
							type='email'
							value={formik.values.email}
							onChange={formik.handleChange}
						/>
					</div>
					<button disabled={formik.isSubmitting} type='submit' className='py-2 w-52 mt-4 mx-auto text-center bg-[#2699FB] rounded-[50px] text-white'>
                        Trocar de senha
					</button>
				</form>
			</div>
		</div>
	)
}
