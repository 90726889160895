import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataGrid, getDefaultGridFilterModel, GridCellParams, GridColDef, GridColumnMenuProps, GridFilterInputMultipleSingleSelectProps, GridFilterInputMultipleValueProps, GridFilterInputSingleSelect, GridFilterItem, GridFilterModel, GridFilterOperator, GridPagination, GridRowsProp, GridToolbarExport, GridValueGetterParams, SortGridMenuItems, useGridApiContext, useGridRootProps } from '@mui/x-data-grid'
import { format, intervalToDuration } from 'date-fns'
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TablePagination, TextField } from '@mui/material'

import { GridFooterContainer, GridFooter } from '@mui/x-data-grid'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import MultiSelect, { Filter, MultiSelectValue } from 'components/MultiSelect'
import { TimelogNS, TimelogData } from 'services/timelogEvents'


const columns: GridColDef[] = [
	{ field: 'owner', headerName: 'Colaborador', width: 400, filterable: false },
	{ field: 'client', headerName: 'Cliente', width: 300, filterable: false },
	{ field: 'quantity', headerName: 'Horas', type: 'number', width: 75, disableColumnMenu: true, sortable: false, valueGetter: (params: GridValueGetterParams<number>) => {
		if (params.value !== undefined) {
			const duration = intervalToDuration({ start: 0, end: params.value * 3600 * 1000 })
			return `${duration.hours}:${duration.minutes ? duration.minutes : '00'}`
		} else {
			return 0
		}
	} },
	{ field: 'date', headerName: 'Data', width: 200, valueGetter: (params) => {
		return format(params.value, 'dd/MM/yyyy')
	} },
]

const filterColumns = [
	{ field: 'owner', headerName: 'Colaborador' },
	{ field: 'client', headerName: 'Cliente' },
	{ field: 'date', headerName: 'Data' },
]

interface Row {
	id: string
	date: Date
	owner: string
	client: string
	quantity: number

}

const CustomFooter = ({ total } : { total: number}) => {
	return (
		<GridFooterContainer>
			<div className='ml-4'>
				<div className='mr-4'>Total: {total}</div>
				<GridToolbarExport />
			</div>

			<GridPagination />
		</GridFooterContainer>
	)
}

export const TimelogContext = React.createContext<React.MutableRefObject<null> | undefined>(undefined)

export const useFilter = () => {
	const context = React.useContext(TimelogContext)
	if (context === undefined) {
		throw new Error('Error occured')
	} else {
		return context
	}
}


const Timelog = () => {
	const [open, setOpen] = useState<boolean>(false)
	const [data, setData] = useState<TimelogData[]>([])
	const [filters, setFilters] = useState<Filter[]>([
		{ id: 'owner', values: [] },
		{ id: 'client', values: [] },
		{ id: 'date', values: [] },
	])
	const [rows, setRows] = useState<Row[]>([])
	const [total, setTotal] = useState<number>(0)
	const filterMenu = useRef(null)

	useEffect(() => TimelogNS.listData(new Date(), data => setData(data)), [])
	useEffect(() => {
		const rows = data.filter(d => {
			const checkedClient = filters
				.find(filter => filter.id === 'client')?.values
				.find(value => value.id === d.client.id)?.checked
			const checkedOwner = filters
				.find(filter => filter.id === 'owner')?.values
				.find(value => value.id === d.owner.id)?.checked
			const checkedDate = filters
				.find(filter => filter.id === 'date')?.values
				.find(value => value.id === d.date.toISOString())?.checked
			return !!checkedClient &&
				!!checkedOwner &&
				!!checkedDate
		}).map(d => ({
			...d,
			owner: d.owner.displayName,
			client: d.client.name,
		}))
		setTotal(rows.reduce((sum, row) => sum + row.quantity, 0))
		setRows(rows)
	}, [filters])


	const filterFilters = (value: MultiSelectValue, index: number, self: MultiSelectValue[]) => {
		return index === self.findIndex(t => t.id === value.id)
	}

	useEffect(() => {
		setFilters([
			{
				id: 'owner',
				values: data.map(d => ({
					id: d.owner.id,
					label: d.owner.displayName,
					checked: true,
				})).filter(filterFilters),
			}, {
				id: 'client',
				values: data.map(d => ({
					id: d.client.id,
					label: d.client.name,
					checked: true,
				})).filter(filterFilters),
			}, {
				id: 'date',
				values: data.map(d => ({
					id: d.date.toISOString(),
					label: format(d.date, 'dd/MM/yyyy'),
					checked: true,
				})).filter(filterFilters),
			},
		])
	}, [data])

	const getFilter = (field: string) => {
		return filters.find(filter => filter.id === field)?.values
	}

	const setFilter = (field: string) => {
		return (newValues: MultiSelectValue[]) => {
			setFilters([...filters.map(filter => {
				if (filter.id === field) {
					return {
						id: field,
						values: newValues,
					}
				} else {
					return filter
				}
			})])
		}
	}

	const toggleFilter = () => setOpen(!open)

	return (
		<TimelogContext.Provider value={filterMenu}>
			<div className='h-full flex pb-4'>
				<DataGrid
					rows={rows}
					columns={columns}
					components={{
						Footer: CustomFooter,
					}}
					componentsProps={{
						footer: { total },
					}}
				/>
				<div className={`${open ? '' : 'hidden'} w-1/3 h-full overflow-y-auto`}>
					{/* <Filter data={data} setData={setData}/> */}
					{filterColumns.map(col =>
						<Accordion key={col.field}>
							<AccordionSummary
          						expandIcon={<ExpandMoreIcon />}
							>
								{col.headerName}
							</AccordionSummary>
							<AccordionDetails>
								<MultiSelect
									values={getFilter(col.field)!}
									setFilter={setFilter(col.field)}
								/>
							</AccordionDetails>
						</Accordion>,
					)}
				</div>
				<div className='border border-gray-300 w-8'>
					<div className=' h-32 w-full flex'>
						<button onClick={toggleFilter} className='my-auto mx-auto'>
							<div style={{ writingMode: 'vertical-lr', WebkitWritingMode: 'vertical-lr' }}>
								Filtrar
							</div>
						</button>
					</div>
				</div>
			</div>
		</TimelogContext.Provider>
	)
}

export default Timelog