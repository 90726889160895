import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { Timestamp } from 'firebase/firestore'
import { Button } from '@mui/material'
import { uploadImage } from 'shared/utils'
import { ptBR } from 'date-fns/locale'
import { format } from 'date-fns'
import { getDownloadURL } from 'firebase/storage'
import { PostNS } from 'services/posts'

export default function AddNew() {
	const [title, setTitle] = useState('')
	const [content, setContent] = useState('')
	const [processing, setProcessing] = useState(false)
	// const [files, setFiles] = useState<FileList | null>()
	const [fileURL, setFileURL] = useState('')

	const handleUpload: React.ChangeEventHandler<HTMLInputElement> = e => {
		if (!e.currentTarget.files) return
		const file = e.currentTarget.files[0]
		uploadImage(file, `${file.name}_${format(new Date(), 'PPpp', { locale: ptBR })}`)
			.then(snapshot => getDownloadURL(snapshot.ref))
			.then(url => setFileURL(url))
	}

	const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = e => {
		setProcessing(true)
		PostNS.create({
			content,
			title,
			date_created: new Date(),
			date_updated: new Date(),
			imageURL: fileURL,
		}).then(() => {
			console.log('done')
			setProcessing(false)
			alert('O seu comunicado foi postado com sucesso!')
		})
	}

	return (
		<div className='w-full'>
			<div className='text-lg'>
                Adicionar Comunicado
			</div>
			<div className='mt-4'>
				<TextField
					className='w-full'
					label='Titulo'
					value={title}
					onChange={e => setTitle(e.currentTarget.value)}/>
			</div>
			<div className='mt-4'>
				<TextField
					className='w-full'
					label='Comunicado'
					multiline
					rows={4}
					value={content}
					onChange={e => setContent(e.currentTarget.value)}
				/>
			</div>
			<div className='mt-4'>
				<Button
					variant='contained'
					component='label'
				>
                    Upload File
					<input
						type='file'
						onChange={handleUpload}
						hidden
					/>
				</Button>
			</div>
			<div className='mt-4'>
				{fileURL &&
                    <img src={fileURL}/>
				}
			</div>
			<div className='mt-4'>
				<Button
					className='bg-iest-primary text-white text-center no-underline align-middle py-1 px-3 rounded-md'
					variant='contained'
					disabled={processing}
					onClick={handleSubmit}
				>
                    Submeter
				</Button>

			</div>
		</div>
	)
}
