import { format, formatDistance } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle } from '@mui/material'
import parse from 'html-react-parser'
import { PostNS, Post } from 'services/posts'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

const LatestPost = () => {
  const [posts, setPosts] = useState<Post[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [index, setIndex] = useState<number>(0)

  posts.sort(function(firstDatePost, secondDatePost) {
    return (
      secondDatePost.date_created.getTime() -
      firstDatePost.date_created.getTime()
    )
  })

  useEffect(
    () =>
      PostNS.list((posts) => {
        setPosts(posts)
        console.log(posts)
      }),
    [],
  )
  if (posts) {
    return (
      <div className='text-center flex flex-row justify-between py-10 p-2 flex-wrap lg:px-20'>
        {posts.map((post, index) => (
          <div key={post.id} className='w-[48%] mb-10 cursor-pointer'>
            <div
              className='w-full h-full flex flex-col mb-4 rounded-3xl bg-slate-200 py-5'
              onClick={() => {
                setOpen(true)
                setIndex(index)
              }}
            >
              <div className='text-gray-700 text-lg font-semibold'>
                {post.title}
              </div>
              <div className='text-gray-500 text-base'>{post.content}</div>
              <div className='text-gray-500 text-xs mx-auto'>
                {format(posts[index].date_created, 'd \'de\' MMMM \'de\' yyyy', {
                  locale: ptBR,
                })}
              </div>
              <img
                className='h-[700px] mt-4 w-auto mx-auto object-contain rounded'
                src={post.imageURL}
              />
            </div>
          </div>
        ))}

        {posts[index] && (
          <Dialog onClose={() => setOpen(false)} open={open}>
            <DialogTitle>
              {posts[index].title}
              <span className='text-gray-500 text-sm ml-3'>
                {format(posts[index].date_created, 'd \'de\' MMMM \'de\' yyyy', {
                  locale: ptBR,
                })}
              </span>
            </DialogTitle>
            <div className='px-4 '>
              <div>
                <TransformWrapper>
                  <TransformComponent>
                    <img src={posts[index].imageURL} />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
          </Dialog>
        )}
      </div>
    )
  } else {
    return <div></div>
  }
}

export default LatestPost
