import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdEmail, MdLock } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Img from 'assets/login_img.svg'
import Lg from 'assets/iest_logo.png'
import { browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from 'firebase/auth'
import { authApp } from 'shared/firebase'
import { Field, Form, Formik, FormikHelpers, useFormik } from 'formik'
// import { TextField } from 'formik-mui'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import * as Yup from 'yup'
import Logo from 'assets/iest_logo-Branco.png'
import { auth } from 'shared/firebase'

interface Values {
	email: string
	password: string
	rememberme: boolean
}

export default function Login() {

	const navigate = useNavigate()
	const loginSchema = Yup.object().shape({
		email: Yup.string().email('Email inválido').required('Obrigatório'),
		password: Yup.string().required('Obrigatório'),
		rememberme: Yup.boolean(),
	})
	const formik = useFormik({
	  	initialValues: {
			email: '',
			password: '',
			rememberme: false,
		},
		validationSchema: loginSchema,
		onSubmit: (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {

			setPersistence(auth, values.rememberme ? browserLocalPersistence : browserSessionPersistence)
				.then(() => signInWithEmailAndPassword(auth, values.email, values.password))
				.then(_res => navigate('/'))
				.catch(error => {
					console.log(error.code)
					switch (error.code) {
					case 'auth/wrong-password':
						setFieldError('password', 'Senha incorreta.')
						break
					case 'auth/user-not-found':
						setFieldError('email', 'Email não encontrado.')
						break
					default:
						setFieldError('email', 'Erro no servidor, favor contatar os administradores')
					}
				}).finally(() => {
					setSubmitting(false)
				})
		},
	})


	// const handleSubmit = (values: Values, { setSubmitting, setFieldError }: FormikHelpers<Values>) => {

	// 	const auth = getAuth(authApp)
	// 	signInWithEmailAndPassword(auth, values.email, values.password).then(_res => {
	// 		navigate('/')
	// 	}).catch(error => {
	// 		console.log(error.code)
	// 		switch (error.code) {
	// 		case 'auth/wrong-password':
	// 			setFieldError('password', 'Senha incorreta.')
	// 			break
	// 		case 'auth/user-not-found':
	// 			setFieldError('email', 'Email não encontrado.')
	// 			break
	// 		default:
	// 			setFieldError('email', 'Erro no servidor, favor contatar os administradores')
	// 		}
	// 	}).finally(() => {
	// 		setSubmitting(false)
	// 	})
	// }

	return (

		<>
			<div className='h-screen w-full bg-slate-400 overflow-y-auto px-4 lg:px-0'>
				<div className='w-32 mx-auto py-24'>
					<img className='w-32' src={Logo}/>
				</div>
				<div className='w-full mx-auto mb-20 bg-white rounded-[100px] shadow-lg px-4  py-6 flex flex-col items-center lg:w-1/2 lg:px-10'>
					<div className='text-[#2699FB] font-bold text-3xl mb-4'>Bem vindo!</div>
					<div className='text-sm mb-8'>Entre com seus dados</div>
					<form onSubmit={formik.handleSubmit} className='flex flex-col w-full'>
						<div className='mb-4 w-full'>
							<TextField
								fullWidth
								label='Email'
								variant='standard'
								name='email'
								type='email'
								value={formik.values.email}
								onChange={formik.handleChange}
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.touched.email && formik.errors.email}
							/>
						</div>
						<div className='mb-4 w-full flex'>
							<TextField
								fullWidth
								label='Senha'
								variant='standard'
								name='password'
								type='password'
								value={formik.values.password}
								onChange={formik.handleChange}
								error={formik.touched.password && Boolean(formik.errors.password)}
								helperText={formik.touched.password && formik.errors.password}
							/>
						</div>
						<div className='flex my-8'>
							{/* <FormControlLabel control={<Checkbox defaultChecked />} label='Label' className='text-sm'/> */}
							<div>
								<input
									type='checkbox'
									name='rememberme'
									checked={formik.values.rememberme}
									onChange={formik.handleChange}
								/>
      							<label htmlFor='rememberme' className='ml-4'>Lembre-me</label>
							</div>
							<Link className='ml-auto no-underline text-iest-primary' to='/esqueci'>esqueci a senha</Link>
						</div>

						<button type='submit' className='py-2 w-52 mt-4 mx-auto text-center bg-[#2699FB] rounded-[50px] text-white'>
          					Entrar
						</button>
					</form>
				</div>
			</div>
			{/* <div className='flex items-center justify-center h-screen w-full max-w-5xl mx-auto'>
				<div className='w-1/3 hidden lg:block '>
					<img src={Img} alt='Imagem de Login' />
				</div>
				<Form className='md:ml-0 flex flex-col justify-items-center items-center ml-12 px-8 w-2/3 space-y-4'>
					<h1 className='text-5xl mb-5'><img src={Lg} alt='Logo IEST' className='w-36 h-14 mb-4'/></h1>

					<Field
						className='w-full'
						component={TextField}
						type='email'
						label='Email'
						name='email'
					/>
					<Field
						className='w-full'
						component={TextField}
						type='password'
						label='Senha'
						name='password'
					/>

					<Button
						className='w-full'
						variant='contained'
						color='primary'
						disabled={isSubmitting}
						onClick={submitForm}
					>
					Entrar
					</Button>

					<h4 className='text-lg'><Link className='no-underline text-iest-primary' to='/esqueci'>Esqueci a senha</Link></h4>
				</Form>
			</div> */}
		</>
	)
}
