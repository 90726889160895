import React from 'react'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import LogoIEST from 'assets/iest_logo.png'
import QRCODE from 'assets/qrcode.png'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { QRCodeSVG } from 'qrcode.react'


const Footer = () => {
	return (
		<div className='bg-[#ECECF9] flex flex-col m-auto items-center justify-center py-10 px-8'>
			<div className='container w-full flex flex-col items-start mx-auto pb-4 lg:justify-between lg:flex-row'>
				<div>
					<img src={LogoIEST} alt='Logo IEST' className='w-32'/>
					<div className='mt-4 flex space-x-2'>
						<a className='border rounded-full border-[#3b5998] p-1' href='https://www.facebook.com/c2biest/'>
							<FacebookIcon
								sx={{
									color: '#3b5998',
									height: '2rem',
								}}
								fontSize='large'
							/>
						</a>
						<a className='border rounded-full border-[#00acee] p-1' href='https://twitter.com/China2Brazil'>
							<TwitterIcon
								sx={{
									color: '#00acee',
									height: '2rem',
								}}
								fontSize='large'
							/>
						</a>
						<a className='border rounded-full border-[#0e76a8] p-1' href='https://www.linkedin.com/company/iestgroup/'>
							<LinkedInIcon
								sx={{
									color: '#0e76a8',
									height: '2rem',
								}}
								fontSize='large'
							/>
						</a>
					</div>
				</div>

				<div className='py-8 lg:py-0 lg:px-8'>
					<div className='mb-2'><Typography variant='h4'>LINKS</Typography></div>
					<Link to='/beneficios'><Typography className='text-[#01448c] hover:text-[rgb(58,129,206)]'>Beneficios</Typography></Link>
					<Link to='/politica'><Typography className='text-[#01448c] hover:text-[rgb(58,129,206)]'>Politicas</Typography></Link>
					<Link to='/manual'><Typography className='text-[#01448c] hover:text-[rgb(58,129,206)]'>Manual</Typography></Link>
					<Link to='/contato'><Typography className='text-[#01448c] hover:text-[rgb(58,129,206)]'>Contato</Typography></Link>
					<Link to='/comunicados'><Typography className='text-[#01448c] hover:text-[rgb(58,129,206)]'>Comunicados</Typography></Link>
					<Link to='/nossos-valores'><Typography className='text-[#01448c] hover:text-[rgb(58,129,206)]'>Nossos valores</Typography></Link>
				</div>
				<div>
					<Typography variant='h4'>CONTATO</Typography>
					<Typography className='pb-2'>LGPD: dpo@iestgroup.com</Typography>
					<Typography>ouvidoria@iestgroup.com</Typography>
				</div>

				<div className='py-8 lg:py-0 lg:px-8'>
					<Typography variant='h4'>SUGESTÕES</Typography>
					<QRCodeSVG className='border-4 rounded border-black p-2 bg-white' value='https://www.questionpro.com/a/TakeSurvey?tt=cnMCaBA7Tj4%3D' />
					<p>Escaneie ou </p><a className='text-[#01448c] font-bold hover:text-[rgb(58,129,206)]' href='https://www.questionpro.com/a/TakeSurvey?tt=cnMCaBA7Tj4%3D'>Clique Aqui</a>
				</div>
			</div>
		</div>
	)
}

export default Footer