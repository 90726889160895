import React, { useEffect, useState } from "react";
import IconeMissao from "assets/icone-missao.png";
import IconeVisao from "assets/icone-visao.png";

const NossosValores = () => {
  return (
    <div className="w-full p-5 lg:p-20">
      <div className="flex flex-col justify-center items-center mb-20">
        <h2 className="w-full text-4xl uppercase text-blue-iest font-bold pb-6 border-b-4 border-b-orange-600">
          NOSSA VISÃO
        </h2>
        <p className="text-2xl text-center text-blue-iest font-medium w-3/4 mt-5">
          Ser uma organização profissional no atendimento de empresas
          multinacionais, oferecendo soluções completas, com boa relação e
          custo-benefício para os clientes.
        </p>
      </div>
      <div className="flex flex-col justify-center items-center mb-20">
        <h2 className="w-full text-4xl uppercase text-blue-iest font-bold pb-6 border-b-4 border-b-orange-600">
          Nossa Missão
        </h2>
        <p className="text-2xl text-center text-blue-iest font-medium w-3/4 mt-5">
          Usar nossas expertises para reduzir barreiras
        </p>
        <div className="w-full mt-5">
          <img
            className="w-40 text-right ml-5"
            src={IconeMissao}
            alt="Nossa visão"
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mb-20">
        <h2 className="w-full text-4xl uppercase text-blue-iest font-bold pb-6 border-b-4 border-b-orange-600">
          Nosso Slogan
        </h2>
        <p className="text-2xl text-center text-blue-iest font-medium w-3/4 mt-5">
          Excelência com Solidez.
        </p>
      </div>
      <div className="mb-20">
        <h2 className="w-full text-4xl uppercase text-blue-iest font-bold pb-6 border-b-4 border-b-orange-600">
          Nossos valores
        </h2>
        <h3 className="text-2xl text-blue-iest font-bold">
          Agir com integridade
        </h3>
        <h4 className="text-xl text-blue-iest font-semibold">Ser honesto</h4>
        <p className="text-xl font-semibold">
          Falar fatos e verdades, o custo da mentira pode sair muito mais caro;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">Ser ético</h4>
        <p className="text-xl font-semibold">
          Agir de forma ética, afastando-se de atos e condutas ilegais,
          preservando sempre a integridade de nossos clientes;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Cumprir compromissos
        </h4>
        <p className="text-xl font-semibold">
          Se comprometer somente com aquilo que for possível realizar, e buscar
          cumprir com os compromissos assumidos, tanto com nossos clientes
          internos quanto clientes externos;
        </p>
        <h3 className="text-2xl text-blue-iest font-bold mt-6">
          Busca por Excelência
        </h3>
        <h4 className="text-xl text-blue-iest font-semibold">Fazer o melhor</h4>
        <p className="text-xl font-semibold">
          Tentar fazer o seu melhor em todos os trabalhos, excelência não é um
          feito, mas sim um hábito;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Assumir responsabilidades
        </h4>
        <p className="text-xl font-semibold">
          Assumir proativamente as responsabilidades de cada um, admitir erro
          próprio e não culpar os outros, isso contribui para o crescimento
          individual da equipe;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Importar-se com os detalhes
        </h4>
        <p className="text-xl font-semibold">
          Importar-se com os detalhes, estes fazem a diferença, e o
          profissionalismo se destaca neles;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Aprender constantemente
        </h4>
        <p className="text-xl font-semibold">
          Estar disposto a adquirir novos conhecimentos, raciocinar os problemas
          com profundidade;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Manter-se atualizado
        </h4>
        <p className="text-xl font-semibold">
          Conhecer as novidades da área, acompanhando os acontecimentos
          recentes;
        </p>
        <h3 className="text-2xl text-blue-iest font-bold mt-6">
          Atuar como parte integrante da organização
        </h3>
        <h4 className="text-xl text-blue-iest font-semibold">Crescer juntos</h4>
        <p className="text-xl font-semibold">
          Importar-se com o crescimento da empresa e dos colegas de trabalho,
          uma empresa bem sucedida é uma empresa formadora de pessoas;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Ajudar mutuamente
        </h4>
        <p className="text-xl font-semibold">
          Estar disposto a ajudar os colegas, ajudas recíprocas contribuem para
          um ambiente de trabalho harmonioso e eficiente;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Liderar por exemplo
        </h4>
        <p className="text-xl font-semibold">
          Cumprir os deveres e agir como exemplo para novos colegas, isso é
          fundamental para todos que ingressem na equipe sigam os mesmos
          valores;
        </p>
        <h3 className="text-2xl text-blue-iest font-bold mt-6">
          Importar-se com as Pessoas
        </h3>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Respeitar os outros
        </h4>
        <p className="text-xl font-semibold">
          Respeitar os membros da equipe, não importando seu cargo ou função;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Ter empatia
        </h4>
        <p className="text-xl font-semibold">
          Pensar no ponto de vista dos outros, sendo compreensivo aos problemas
          e dificuldades dos membros da equipe;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Comunicar com sinceridade
        </h4>
        <p className="text-xl font-semibold">
          Tratar as pessoas com sinceridade, buscando aumentar a eficiência e
          reduzindo desgastes internos;
        </p>
        <h3 className="text-2xl text-blue-iest font-bold mt-6">
          Satisfação do Cliente
        </h3>
        <h4 className="text-xl text-blue-iest font-semibold">
          Focar em soluções
        </h4>
        <p className="text-xl font-semibold">
          Os clientes não precisam de uma mera resposta, precisam de soluções e
          o caminho para executá-las;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Respeitar clientes
        </h4>
        <p className="text-xl font-semibold">
          Respeitar os clientes, valorizar os feedbacks dos clientes;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Focar em profissionalismo
        </h4>
        <p className="text-xl font-semibold">
          Manter profissionalismo ao atender as demandas dos clientes, tentar
          conciliar as exigências deles com nosso conhecimento e atitude
          profissional;
        </p>
        <h4 className="text-xl text-blue-iest font-semibold mt-6">
          Ser pontual e proativo
        </h4>
        <p className="text-xl font-semibold">
          Responder e-mails e mensagens do cliente interno e externo em 24 horas
          (dias úteis), respeitar os horários marcados e ser proativo no
          atendimento.
        </p>
      </div>
    </div>
  );
};

export default NossosValores;
