import React, { useEffect, useState } from 'react'
import { CircularProgress, TextField } from '@mui/material'
import { DepartmentNS } from 'services/departments'
import { ModerationNS } from 'services/moderationTeams'
import { DataGrid, GridActionsCellItem, GridColumns, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridRowsProp, MuiEvent } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import EditToolbar from './EditToolbar'
import { useAuth } from 'shared/auth'
import { User, UserNS, periods } from 'services/user'

const Users = () => {
	const { currentUser } = useAuth()
	const [search, setSearch] = useState<string>('')
	const [users, setUsers] = useState<GridRowsProp<User>>([])
	const [filtered, setFiltered] = useState<GridRowsProp<User>>([])
	const [columns, setColumns] = useState<GridColumns>([])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
	const [loading, setLoading] = useState(true)
	const [departments, setDepartments] = useState<string[]>([])
	const [moderationTeams, setmoderationTeams] = useState<string[]>([])

	useEffect(() => {
		DepartmentNS.list((departments) => {
			const departmentsName = departments.map((department) => {
				return department.name
			})
			setDepartments(departmentsName)
		})
		ModerationNS.list((moderations) => {
			const teamsName = moderations.map((moderation) => {
				return moderation.name
			})
			setmoderationTeams(teamsName)
		})
	}, [])

	function filterUsers(user: User) {
		const userDepartment = currentUser?.department
		if (currentUser?.displayName === 'Aniely Silva') {
			return user
		} else if (userDepartment === 'Moderação de Conteúdo') {
			if (user.department === userDepartment) {
				return user
			}
		} else {
			return user
		}
	}

	useEffect(() => UserNS.list(users => {
		setUsers(users.sort((first, second) => first.displayName.localeCompare(second.displayName))
			.filter(filterUsers)
			.map((user, index) => ({ ...user })))
		setLoading(false)
	}), [])

	useEffect(() => {
		setFiltered(users.filter(user => user.displayName.toLowerCase().includes(search.toLowerCase())))
	}, [search, users])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>,
	) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}

	const handleDeleteClick = (id: GridRowId) => () => {
		if (typeof id === 'number') return
		if (!currentUser?.admin) {
			alert('Você não tem permissões suficientes para realizar essa ação')
			return
		}
		if (confirm('Deseja deletar esse usuário mesmo?')) {
			setLoading(true)
			UserNS.remove(id).then(() => setLoading(false))
		}
		// setRows(filtered.filter((row) => row.id !== id))
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
		  ...rowModesModel,
		  [id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		// const editedRow = filtered.find((row) => row.id === id)
		// if (editedRow!.isNew) {
		//   setRows(filtered.filter((row) => row.id !== id))
		// }
	}


	useEffect(() => {
		setColumns([
			{
				field: 'displayName',
				headerName: 'Nome',
				width: 400,
				editable: currentUser?.admin,
			}, {
				field: 'email',
				headerName: 'Email',
				width: 350,
			}, {
				field: 'birthday',
				headerName: 'Aniversário',
				width: 200,
				type: 'date',
				editable: true,
			}, {
				field: 'period',
				headerName: 'Periodo',
				type: 'singleSelect',
				width: 250,
				editable: true,
				valueOptions: [...periods],
			}, {
				field: 'department',
				headerName: 'Departamento',
				type: 'singleSelect',
				width: 250,
				editable: true,
				valueOptions: [...departments],
			}, {
				field: 'moderationTeam',
				headerName: 'Equipe',
				width: 200,
				type: 'singleSelect',
				editable: true,
				valueOptions: [...moderationTeams],
			}, {
				field: 'actions',
				type: 'actions',
				headerName: 'Ações',
				width: 100,
				cellClassName: 'actions',
				getActions: ({ id }) => {
					const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
					if (isInEditMode) {
						return [
							<GridActionsCellItem
								icon={<SaveIcon />}
								label='Save'
								onClick={handleSaveClick(id)}
							/>,
							<GridActionsCellItem
								icon={<CancelIcon />}
								label='Cancel'
								className='textPrimary'
								onClick={handleCancelClick(id)}
								color='inherit'
							/>,
						]
					}

					return [
				  		<GridActionsCellItem
							icon={<EditIcon />}
							label='Edit'
							className='textPrimary'
							onClick={handleEditClick(id)}
							color='inherit'
						/>,
						<GridActionsCellItem
							icon={<DeleteIcon />}
							label='Delete'
							onClick={handleDeleteClick(id)}
							color='inherit'
				  		/>,
					]
			  	},
			},
		])
	}, [rowModesModel])

	const processRowUpdate = async (newRow: GridRowModel<User>) => {
		setLoading(true)
		await UserNS.update(newRow.id, newRow)
		setLoading(false)
		return newRow
	}

	return (
		<div className='h-full pb-4 flex flex-col mr-10'>
			<div className='mb-4'><TextField label='Pesquisar' variant='outlined' value={search} onChange={event => setSearch(event.target.value)}/></div>
			{loading && <CircularProgress/>}
			<DataGrid
				rows={filtered}
				columns={columns}

				editMode='row'
				rowModesModel={rowModesModel}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				onProcessRowUpdateError={error => console.log(error)}
				components={{
					Toolbar: EditToolbar,
				}}
				componentsProps={{
					toolbar: { periods, departments, moderationTeams },
				}}
				experimentalFeatures={{ newEditingApi: true }}
			/>
		</div>
	)
}

export default Users