import React, { useEffect, useState } from 'react'
import { getDocument, updateDocument } from 'shared/utils'
import FileInput from './FileInput'
import { FaUpload } from 'react-icons/fa'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Policies from './Policies'
import Manuals from './Manuals'

interface IState {
	[id: string]: File
}

type DocumentType = 'newspaper' | 'politics' | 'manual'

const Documents = () => {


	return (
		<div>
			<div className='mb-4'>O portal suporta apenas arquivos .pdf no momento, converta os arquivos antes de subir</div>
			<div className=' mr-10'>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						Politicas
					</AccordionSummary>
					<AccordionDetails>
						<Policies/>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						Manuais
					</AccordionSummary>
					<AccordionDetails>
						<Manuals/>
					</AccordionDetails>
				</Accordion>
				{/* <FileInput loading={loading} setLoading={setLoading} name='politics'>Politicas</FileInput>
				<FileInput loading={loading} setLoading={setLoading} name='manual'>Manual do Colaborador</FileInput>
				<FileInput loading={loading} setLoading={setLoading} name='manual-contabil'>Manual do setor Contábil</FileInput>
				<FileInput loading={loading} setLoading={setLoading} name='manual-dp'>Manual do setor DP</FileInput>
				<FileInput loading={loading} setLoading={setLoading} name='manual-financeiro'>Manual do setor Financeiro</FileInput>
				<FileInput loading={loading} setLoading={setLoading} name='manual-fiscal'>Manual do setor Fiscal</FileInput>
				<FileInput loading={loading} setLoading={setLoading} name='manual-paralegal'>Manual do setor Paralegal</FileInput> */}


			</div>
		</div>
	)
}

export default Documents