export type StatusValue = 'trabalho' | 'trabalhoP' | 'trabalhoB' | 'trabalhoS' | 'ferias' | 'folga' | 'feriado' | 'outros'

type Status = {
	[key: string]: StatusValue
}

type StatusReversed = {
	[key in StatusValue]: string
}
const statuses: Status = {
	'W': 'trabalho',
	'P': 'trabalhoP',
	'B': 'trabalhoB',
	'S': 'trabalhoS',
	'V': 'ferias',
	'O': 'folga',
	'H': 'feriado',
	'M': 'outros',
}
const statusesReversed: StatusReversed = {
	'trabalho': 'W',
	'trabalhoP': 'P',
	'trabalhoB': 'B',
	'trabalhoS': 'S',
	'ferias': 'V',
	'folga': 'O',
	'feriado': 'H',
	'outros': 'M',
}
export class ScheduleData {
	private data: StatusValue[][]
	constructor(value: string[]) {
		this.data = value.map(statusMonth => {
			return [...statusMonth].map(c => {
				if (!'WPBVOHMS'.includes(c)) {
					throw new Error('wrong type ' + statusMonth)
				}
				return statuses[c]
			})
		})
	}

	getStatus(month: number, date: number) {
		return this.data[month][date - 1]
	}

	toFirestore() {
		return this.data.map(statusMonth =>
			statusMonth.map(status => statusesReversed[status]).join(''),
		)
	}

	toggle(date: Date) {
		const month = date.getMonth()
		const index = date.getDate() - 1

		if (this.data[month][index] === 'trabalho') this.data[month][index] = 'folga'
		else this.data[month][index] = 'trabalho'
	}

	set(date: Date, newStatus: StatusValue) {
		if (this.data[date.getMonth()][date.getDate() - 1] === newStatus) {
			this.data[date.getMonth()][date.getDate() - 1] = 'trabalho'
		} else {
			this.data[date.getMonth()][date.getDate() - 1] = newStatus
		}
	}

	clear(month?: number) {
		if (!month) return
		this.data[month].forEach(function(part, index, arr) {
			arr[index] = 'trabalho'
		})
	}
}