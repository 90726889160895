import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { limit, orderBy, query } from 'firebase/firestore'
import { ptBR } from 'date-fns/locale'
import { format } from 'date-fns'
import { PostNS, Post } from 'services/posts'

export default function List() {
	const [posts, setPosts] = useState<Post[]>([])

	useEffect(() => PostNS.list(posts => setPosts(posts)), [])

	const handleDelete: React.MouseEventHandler<SVGElement> = async (e) => {
		console.log(e.currentTarget.id)
		if (window.confirm('Deseja apagar esse comunicado?')) {
			console.log('accept')
			PostNS.remove(e.currentTarget.id).then(res => {
				window.location.reload()
			}).catch(error => console.log(error))
		}
	}
	return (
		<div className='w-full'>
			<div className='mb-4 border-b border-solid border-iest-primary'>Comunicados</div>
			<div className='flex flex-col'>
				{posts.map((post, index) =>
					<div key={index} className='mb-4 ml-2'>
						<div className='flex'>
							<Link className='hover:underline' to={`/admin/posts/${post.id}`}>
								<span>{post.title}</span>
								<span className='ml-2 font-semibold'>
									{format(post.date_created, 'PPpp', { locale: ptBR })}
								</span>
							</Link>
							<div className='my-auto ml-2'>
								<FaTrash className='my-auto cursor-pointer' id={post.id} onClick={handleDelete}/>
							</div>
						</div>
					</div>,
				)}
				<Link className='mb-4 ml-2' to={'/admin/posts/adicionar'}>
					<span>+</span>
					<span className='ml-2'>Adicionar novo comunicado</span>
				</Link>
			</div>
		</div>
	)
}
