import React from "react";
import { Link } from "react-router-dom";

const data = [
  {
    name: "Site Iest Group",
    img: "logo-iest.png",
    url: "https://iestgroup.com/",
  },
  {
    name: "Site Iest Tecnologia",
    img: "logo-itec.png",
    url: "https://iesttech.com.br/",
  },
  {
    name: "Site Iest Consultoria",
    img: "logo-izi.png",
    url: "https://iestconsulting.com/",
  },
  {
    name: "Site Iest Contabil",
    img: "logo-iku.png",
    url: "https://iestcontabeis.com/",
  },
  {
    name: "Blog China2Brazil",
    img: "logo-c2b.png",
    url: "https://china2brazil.com.br/",
  },
  {
    name: "Blog Alibaba",
    img: "logo-alibaba.png",
    url: "https://alibaba.iestgroup.com/",
  },
  {
    name: "Site Isee Studio",
    img: "logo-isee.png",
    url: "https://iseestudio.com.br/",
  },
];

export default function Sites() {
  return (
    <div className="min-h-screen w-full flex flex-col justify-start items-center pt-8">
      <h2 className="text-3xl font-bold text-blue-iest uppercase">
        Sites Grupo Iest
      </h2>
      <p className="mb-8 text-sm text-gray-500">
        Para acessar um dos sites basta clicar em cima.
      </p>
      <div className="w-full flex justify-center items-center flex-wrap space-y-2 md:space-x-4 md:space-y-0 md:justify-center md:max-w-[700px]">
        {data.map((data, idx) => (
          <div
            key={idx}
            className="w-[48%] flex flex-col justify-center items-center md:w-auto"
          >
            <a
              className="rounded-full border border-gray-300 w-28 h-28 md:w-32 md:h-32 overflow-hidden flex justify-center items-center md:mt-4 hover:bg-blue-200 transition-all duration-300"
              href={data.url}
              target="_blank"
            >
              <img className=" w-full" src={`logos/${data.img}`} />
            </a>
            <p className="mt-2 text-sm text-gray-700">{data.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
