import { initializeApp } from 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { getStorage } from 'firebase/storage'

export const app = initializeApp({
	apiKey: 'AIzaSyDlD6bqCCYyXYXDhxeV2plDvlN-LPpM7jE',
	authDomain: 'iestportal.firebaseapp.com',
	projectId: 'iestportal',
	storageBucket: 'iestportal.appspot.com',
	messagingSenderId: '407822163026',
	appId: '1:407822163026:web:2e56c3552c9e3c82e6938b',
	measurementId: 'G-GMZH9P6P8R',
})

export const authApp = initializeApp({
	apiKey: 'AIzaSyARJe1AS-KMRiu7HHKN-4Q2WYx_AOcgSbs',
	authDomain: 'iest-authentication.firebaseapp.com',
	projectId: 'iest-authentication',
	storageBucket: 'iest-authentication.appspot.com',
	messagingSenderId: '289787099400',
	appId: '1:289787099400:web:176ba323d13cff46f594bd',
}, 'auth')


export const auth = getAuth(authApp)
export const authFunctions = getFunctions(authApp)
export const authFirestore = getFirestore(authApp)
export const functions = getFunctions(app)
export const firestore = getFirestore(app)
export const storage = getStorage(app)

// connectFunctionsEmulator(functions, 'localhost', 5001)
// connectStorageEmulator(storage, 'localhost', 9199)

