import { TextField } from '@mui/material'
import { addDoc, collection, deleteDoc, doc, getDocs, getFirestore, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore'
import { deleteObject, getStorage, ref, uploadBytes } from 'firebase/storage'
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineDelete, AiOutlineFileAdd, AiOutlinePlus } from 'react-icons/ai'

interface IFile {
    id: string
    name: string
}

const Policies = () => {

    const [files, setFiles] = useState<IFile[]>([])
    const db = getFirestore()
    const storage = getStorage()
    const [loading, setLoading] = useState(false)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
		if (!e.target.files) return
		setLoading(true)
		const file = e.target.files[0]
        uploadBytes(ref(storage, `documents/${id}.pdf`), file).then(() => {
            alert('Documento atualizado')
        }).finally(() => {
            setLoading(false)
        })
	}
    useEffect(() => {
        getDocs(query(collection(db, 'documents'), where('type', '==', 'policy'))).then((query) => {
            setFiles(query.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name,
                createdAt: doc.data().createdAt,
            })).sort((first, second) => Number(first.createdAt) - Number(second.createdAt)))
        })
    }, [])

    return (
        <div>
            <ul className='p-4 space-y-4'>
                {files.map(file => (
                    <li key={file.id} className='flex items-center w-full'>
                        <TextField fullWidth value={file.name} onChange={e => {
                            setFiles(files.map(f => f.id === file.id ? { ...f, name: e.currentTarget.value } : f))
                            updateDoc(doc(db, 'documents', file.id), { name: e.currentTarget.value })
                        }}/>
                        <label className={'ml-4 text-xl border border-gray-300 rounded-md p-2 ' + (loading ? 'text-gray-300' : 'cursor-pointer')}>
                            <input
                                disabled={loading}
                                type='file'
                                hidden
                                onChange={e => handleChange(e, file.id)}
                                onClick={e => e.currentTarget.value = ''}
                            />
                            <AiOutlineFileAdd />
                        </label>
                        <button className='ml-4 text-xl text-red-500 border border-gray-300 rounded-md p-2' onClick={() => {
                            if (confirm('Você deseja mesmo deletar este documento?')) {
                                deleteDoc(doc(db, 'documents', file.id)).then(() => {
                                    deleteObject(ref(storage, `documents/${file.id}.pdf`))
                                    setFiles([...files].filter(f => f.id !== file.id))
                                })
                            }
                        }}>
                            <AiOutlineDelete />
                        </button>
                    </li>
                ))}
                <li className='p-4'>
                    <button className='flex items-center' onClick={() => {
                        addDoc(collection(db, 'documents'), { name: '', type: 'policy', createdAt: new Date() }).then(docRef => {
                            setFiles([...files, { id: docRef.id, name: '' }])
                        })
                    }}>
                        <AiOutlinePlus />
                        <div>Adicionar um novo</div>
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default Policies