import { format, formatDistance } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle } from '@mui/material'
import parse from 'html-react-parser'
import { PostNS, Post } from 'services/posts'
import { Link } from 'react-router-dom'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

const LatestPost = () => {

	const [post, setPost] = useState<Post>()
	const [open, setOpen] = useState<boolean>(false)


	useEffect(() => PostNS.list(posts => {
		posts.sort((firstDatePost, secondDatePost) => {
			return secondDatePost.date_created.getTime() - firstDatePost.date_created.getTime()
		})
		setPost(posts[0])
	}), [])

	if (post) {
		const formatedDate = ((post?.date_created.getDate())) + '/' + ((post?.date_created.getMonth() + 1)) + '/' + post?.date_created.getFullYear()
		return (
			<div className='text-center flex flex-col'>
				<div key={post.id} className='w-full mb-4 cursor-pointer' onClick={() => {
					setOpen(true)
				}}>
					<div className='w-full flex flex-col mb-4 rounded-3xl bg-slate-200 py-5' >
						<div className='text-gray-700 text-lg font-semibold'>
							{post.title}
						</div>
						<div className='text-gray-500 text-base'>
							{post.content}
						</div>
						<div className='text-gray-500 text-xs mx-auto'>
							{format(post.date_created, 'd \'de\' MMMM \'de\' yyyy', { locale: ptBR })}
						</div>
						<div className='w-full flex justify-center'>
							<img className='h-[600px] mt-4 w-auto mx-auto object-contain rounded' src={post.imageURL}/>
						</div>
					</div>

				</div>

				<div>
					<p>Para visualizar todos os comunicados <Link className={'text-blue-iest font-bold'} to={'comunicados'}>clique aqui</Link>.</p>
				</div>
				{post &&
					<Dialog onClose={() => setOpen(false)} open={open}>
						<DialogTitle>
							{post.title}
							<span className='text-gray-500 text-sm ml-3'>{format(post.date_created, 'd \'de\' MMMM \'de\' yyyy', { locale: ptBR })}</span>
						</DialogTitle>
						<div className='px-4 '>
							<div className='mb-4'>
								<span>{parse(post.content)}</span>
							</div>
							<div>
								<TransformWrapper>
									<TransformComponent>
										<img src={post.imageURL}/>
									</TransformComponent>
								</TransformWrapper>
							</div>
						</div>
					</Dialog>
				}

			</div>
		)
	} else {
		return <div></div>
	}
}

export default LatestPost