import React, { ChangeEvent, useRef, useState } from 'react'
import { Cake, Email } from '@mui/icons-material'
import { Button, Modal, Box } from '@mui/material'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { sendPasswordResetEmail } from 'firebase/auth'
import { FaBuilding } from 'react-icons/fa'
import { MdPhotoCamera } from 'react-icons/md'
import { auth } from 'shared/firebase'
import { useAuth } from 'shared/auth'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import { UserNS } from 'services/user'

interface File {
  name: string
}

const Profile = () => {
  const { currentUser } = useAuth()
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [urlImage, setUrlImage] = useState('')
  const [file, setFile] = useState<File | any>()
  const [loading, setLoading] = useState(false)
  const profileInput = useRef<HTMLInputElement>(null)

  function handleClose() {
    setOpenModal(false)
  }

  function handleChange(event: any) {
    setFile(event.target.files[0])
    const [profileFile] = event.target.files
    setOpenModal(true)
    setUrlImage(URL.createObjectURL(profileFile))
  }

  const handleUpload = () => {
    if (!file) {
      alert('Por favor adicione uma foto antes')
    }
    const storage = getStorage()

    if (file) {
      const storageRef = ref(storage, `/profile_images/${file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          if (snapshot.state === 'running') {
            setLoading(true)
          }
        },
        (err) =>
          alert(
            'Não foi possivel alterar sua foto de perfil, se o erro persistir entre em contato com o TI e informe o seguinte erro: ' +
              err,
          ),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            if (currentUser?.id) {
              setLoading(false)
              UserNS.update(currentUser?.id, { photoURL: url })
              setOpenModal(false)
              alert('Imagem de perfil alterada com sucesso!')
            }
          })
        },
      )
    }
  }

  const changePassword = () => {
    if (window.confirm('Deseja mudar a senha?')) {
      sendPasswordResetEmail(auth, currentUser!.email).then(() => {
        alert('Enviamos um link ao seu email para redefinir a sua senha')
      })
    }
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
  }

  return (
    <div className='h-screen bg-gray-100'>
      <div className='h-screen flex flex-col container items-center justify-center w-auto mx-auto'>
        <div className='flex items-center justify-center mb-8'>
          <div
            className='rounded-full h-20 w-20 overflow-hidden relative'
            onMouseOver={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            {open && (
              <>
                <Button
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    backgroundColor: 'rgb(0 0 0 / 0.5)',
                    width: '100%',
                    height: '100%',
                    padding: '0',
                    '&:hover': { backgroundColor: 'rgb(0 0 0 / 0.5)' },
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    profileInput.current?.click()
                  }}
                >
                  <label
                    className='text-lg mx-auto text-white cursor-pointer flex flex-col justify-center items-center'
                    htmlFor='file'
                  >
                    <MdPhotoCamera />
                    <p className='text-sm'>Mudar</p>
                  </label>
                </Button>
                <input
                  hidden
                  accept='image/*'
                  id='file'
                  name='file'
                  ref={profileInput}
                  onChange={handleChange}
                  type='file'
                />
              </>
            )}
            <img src={currentUser?.photoURL} />
          </div>
          <div className='flex flex-col px-4'>
            <span className='my-2 font-semibold text-xl'>
              {currentUser?.displayName}
            </span>
          </div>
        </div>

        <Modal open={openModal} onClose={handleClose}>
          <Box sx={style}>
            <div>
              <p className='leading-6'>
                {' '}
                Tem certeza que deseja alterar para essa a sua foto de perfil?{' '}
              </p>
              <img className='py-4' src={urlImage} alt='Foto de perfil' />
              {file && (
                <Button
                  variant='contained'
                  component='label'
                  onClick={handleUpload}
                >
                  Salvar
                </Button>
              )}
              {loading && <p>Salvando...</p>}
            </div>
          </Box>
        </Modal>

        <div className='mb-4 flex items-center'>
          <div className='mr-2'>
            <Email />
          </div>
          <div>{currentUser?.email}</div>
        </div>
        <div className='mb-4 flex items-center'>
          <div className='mr-2'>
            <Cake />
          </div>
          <div>
            {format(currentUser!.birthday, 'd \'de\' LLLL', { locale: ptBR })}
          </div>
        </div>
        <div className='mb-4 flex items-center'>
          <div className='mr-2'>
            <FaBuilding />
          </div>
          <div>{currentUser?.department}</div>
        </div>
        <div className='mb-4 flex items-center'>
          <Button variant='contained' onClick={changePassword}>
            Mudar a senha
          </Button>
        </div>
        <div className='flex items-center text-gray-500'>
          <div>Caso haja algum erro, favor entrar em contato com RH</div>
        </div>
      </div>
    </div>
  )
}

export default Profile
