import React, { useEffect, useRef, useState } from 'react'
import { Button, TextField as MuiTextField } from '@mui/material'
import { httpsCallable } from 'firebase/functions'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import { Link } from 'react-router-dom'
import { functions, storage } from 'shared/firebase'
import * as Yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers'
import { lastDayOfMonth, startOfMonth } from 'date-fns'
import { getDownloadURL, ref } from 'firebase/storage'
import LinearProgress from '@mui/material/LinearProgress'
import { uploadFile } from 'shared/utils'
import { FileUpload } from '@mui/icons-material'

export interface Values {
	date: Date
	emissionDate: Date
	finalDate: Date
	startNumber: number
	serviceCode: number
	rate: number
	providerMunicipalSubscription: number
}

const Schema = Yup.object().shape({
	date: Yup.date(),
	emissionDate: Yup.date(),
	finalDate: Yup.date(),
	startNumber: Yup.number(),
	serviceCode: Yup.number(),
	rate: Yup.number(),
	providerMunicipalSubscription: Yup.number(),
})

const CustomField = (props: {label: string, name: string}) => {
	return (
		<Field className='w-full' component={TextField} type='number' label={props.label} name={props.name}/>
	)
}

const RPS = () => {


	const [downloadUrl, setDownloadUrl] = useState('')
	const [templateUrl, setTemplateUrl] = useState('')

	const [file, setFile] = useState(new File([], ''))

	useEffect(() => {
		getDownloadURL(ref(storage, 'rps_files/Modelo de Conversão.xlsx'))
			.then(url => setTemplateUrl(url))
	}, [])

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		if (!e.target.files) return
		const file = e.target.files[0]
		uploadFile(file).then(() => setFile(file))
	}

	return (
		<Formik
			initialValues={{
				municipalInscription: 0,
				startDate: startOfMonth(new Date()),
				endDate: lastDayOfMonth(new Date()),
			}}
			validationSchema={Schema}
			onSubmit={async (values) => {
				setDownloadUrl('')
				const convertRPS = httpsCallable(functions, 'convertRPS')
				await convertRPS({ filePath: `converter/${file.name}`, fields: values })
					.then(res => {
						const data = res.data as any
						console.log(data)
						if (data.errors.length !== 0) {
							let errorText = 'Houve erro com os seguintes cpf/cnpj:\n'
							data.errors.forEach((error: any) => {
								errorText += `${error}\n`
							})
							errorText += 'Acrescente as linhas mencionadas manualmente ou ajuste a planilha'
							alert(errorText)
						}
						return getDownloadURL(ref(storage, data.path as string))
					})
					.then(url => {
						setDownloadUrl(url)
					})
					.catch(e => {
						alert('Ocorreu um erro, verifique a planilha e o layout do RPS (Instruções) e certifique-se que todos os dados estão com a formatação correta')
						console.error(e)
					})

			}}
		>
			{({ submitForm, isSubmitting, values, setFieldValue, setSubmitting, setFieldError }) => (
				<Form
					className='flex flex-col space-y-4 p-10'
				>
					<CustomField label='Inscrição municipal do Prestador' name='municipalInscription' />
					<DatePicker
						label=' Data de Início do Período Transferido no Arquivo '
						inputFormat='dd/MM/yyyy'
						value={values['startDate']}
						onChange={e => setFieldValue('startDate', e!)}
						renderInput={(params) => <MuiTextField {...params} />}
					/>
					<DatePicker
						label='Data de Fim do Período Transferido no Arquivo'
						inputFormat='dd/MM/yyyy'
						value={values['endDate']}
						onChange={e => setFieldValue('endDate', e!)}
						renderInput={(params) => <MuiTextField {...params} />}
					/>

					{templateUrl &&
                    <div className='flex items-center'>
                    	<Button
                    	variant='contained'
                    	color='primary'
                    	>
                    		<a href={templateUrl} target='_blank' rel='noreferrer'>
                                Baixar Modelo
                    		</a>
                    	</Button>
                    	<a href='https://nfe.prefeitura.sp.gov.br/arquivos/nfe_layout_rps.pdf' className='ml-4 text-blue-800' target='_blank' rel='noopener noreferrer'>
                            Instruções
                    	</a>
                    </div>
					}
					<div className='flex items-center'>

						<Button variant='contained' component='label'>
							<FileUpload/><span className='ml-2'>Planilha</span>
							<input type='file' hidden onChange={handleChange}/>
						</Button>
						<div className='ml-4'>{file.name}</div>
					</div>
					{file.name !== '' &&
                    <div>
                    	{ isSubmitting && <LinearProgress />}
                    	<Button
                    		className='w-full'
                    		variant='contained'
                    		color='secondary'
                        	disabled={isSubmitting}
                    		onClick={submitForm}
                    	>
                            Submeter
                    	</Button>
                    </div>
					}
					{downloadUrl &&
					<a href={downloadUrl} target='_blank' rel='noreferrer'>
						<Button
							className='w-full'
							variant='contained'
							color='primary'
						>
						Baixar
						</Button>
					</a>
					}
				</Form>
			)}
		</Formik>
	)
}

export default RPS
