import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

export interface MultiSelectValue {
	id: string
	label: string
	checked: boolean
}


export interface Filter {
	id: string
	values: MultiSelectValue[]
}

interface ISelectProps {
	values: MultiSelectValue[]
	setFilter: (newValues: MultiSelectValue[]) => void
}
const MultiSelect = (props: ISelectProps) => {
	const [searchBox, setSearchBox] = useState<string>('')
	const [options, setOptions] = useState<MultiSelectValue[]>([])
	useEffect(() => {
		setOptions(props.values)
	}, [])

	useEffect(() => {
		if (searchBox !== '') {
			setOptions(props.values.filter(value => value.label.toLowerCase().includes(searchBox.toLowerCase())))
		} else {
			setOptions(props.values)
		}
	}, [searchBox, props.values])
	return (
		<div className='px-4 py-3'>
			{/* searchbox
			<div><input /></div> */}
			<div className='w-full'>
				<TextField fullWidth variant='outlined' label='Pesquisar' value={searchBox} onChange={e => setSearchBox(e.currentTarget.value)} />
			</div>


			{/* options */}
			<div>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={props.values.every(value => value.checked)}
								indeterminate={props.values.some(value => !value.checked) && props.values.some(value => value.checked)}
								onChange={(e, checked) => {
									if (!props.values) return
									props.setFilter([...props.values.map(value => {
										return { ...value, checked }
									})])

								}}
							/>
						}
						label='Selecionar todos'
					/>
					{options.map(option =>
						<FormControlLabel
							key={option.id}
							control={
								<Checkbox
									checked={option.checked}
									onChange={(e, checked) => {
										if (!props.values) return
										props.setFilter([...props.values.map(value => {
											if (value.id === option.id) return { ...value, checked }
											else return value
										})])

									}}
								/>
							}
							label={option.label}
						/>,
					)}

				</FormGroup>
			</div>
		</div>
	)
}

export default MultiSelect