import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAuth } from 'shared/auth'
import { Autocomplete, Modal, Stack, TextField, Button } from '@mui/material'
import { Timestamp } from 'firebase/firestore'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
// import './ScheduleShow.css'
import { add, format, isSameDay, parse, startOfDay, sub } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Timelog, TimelogNS } from 'services/timelogEvents'
import { Client, ClientNS } from 'services/clients'

const Toolbar = ({ currentDate, setCurrentDate }: {currentDate: Date, setCurrentDate: React.Dispatch<React.SetStateAction<Date>>}) => {
	return (
		<div className='grid grid-cols-3 w-full mb-6'>
			<div className='flex my-auto'>{format(currentDate, 'PPPP', { locale: ptBR })}</div>
			<div className='uppercase text-center'>
				<div className='text-base font-extrabold'>{format(currentDate, 'LLLL', { locale: ptBR })}</div>
				<div className='text-sm font-semibold'>{format(currentDate, 'cccc', { locale: ptBR })}</div>
			</div>
			<div className='flex space-x-4'>
				<button className='ml-auto rounded-md border border-gray-300 px-4 py-2'
					onClick={e => setCurrentDate(sub(currentDate, { days: 1 }))}
				>
					<ArrowBackIosNewIcon/>
				</button>
				{!isSameDay(currentDate, new Date()) &&
					<button className='rounded-md border border-gray-300 px-8 py-2'
						onClick={e => setCurrentDate(new Date())}
					>
								Hoje
					</button>
				}
				<button className='rounded-md border border-gray-300 px-4 py-2'
					onClick={e => setCurrentDate(add(currentDate, { days: 1 }))}
				>
					<ArrowForwardIosIcon/>
				</button>
			</div>
		</div>
	)
}

const TimelogPage = () => {
	const startsTimes = [...Array(48).keys()].map((value, index) => {
		const hour = Math.floor(value / 2)
		const minute = (value % 2 * 30).toString().padStart(2, '0')
		return {
			hour: hour + ':' + minute,
			id: index,
		}
	})

	const { currentUser } = useAuth()
	const [currentDate, setCurrentDate] = useState(new Date())
	const [selecting, setSelecting] = useState<0 | 1 | 2>(0)
	const [show, setShow] = useState(false)

	const [clients, setClients] = useState<Client[]>([])
	const [timelogs, setTimelogs] = useState<Timelog[]>([])

	const [showEditing, setShowEdition] = useState(false)
	const [timelogUnique, setTimelogUnique] = useState<Timelog>()
	const [startChange, setStartChange] = useState(0)
	const [endChange, setEndChange] = useState(0)

	const [top, setTop] = useState(0)
	const [start, setStart] = useState(0)
	const [end, setEnd] = useState(0)
	const calendarClickRef = useRef<HTMLDivElement>(null)

	const aux = startOfDay(currentDate)
	console.log(timelogs)
	useEffect(() => TimelogNS.list(currentUser?.id, timelogs => setTimelogs(timelogs)), [])
	useEffect(() => ClientNS.list(clients => setClients(clients)), [])

	const handleEventClick = (timelog: Timelog) => {
		setShowEdition(true)
		setTimelogUnique(timelog)
	}

	const handleClickButtonDelete = (timelog: Timelog) => {
		if (confirm(`Deseja deletar o evento '${timelog.client.name}'?`)) TimelogNS.remove(timelog.id)
		setShowEdition(false)
	}

	const handleUpdateInitalHour = (value: number) => {
		setStartChange(value)
	}

	const handleUpdateEndHour = (value: number) => {
		setEndChange(value)
	}

	const handleUpdateTimelog = () => {
		if (endChange < startChange) {
			alert('O horário de término não pode ser anterior ao horário de início. Por favor, corrija os horários.')
		} else {
			aux.setHours(0)
			if (timelogUnique) {
				TimelogNS.update(timelogUnique.id, {
					start: add(aux, { minutes: 30 * startChange }),
					end: add(aux, { minutes: 30 * (endChange + 1) }),
				})
				setShowEdition(false)
			}
		}
	}

	const handleAutocompleteChange = (client: Client | null) => {
		if (client === null) return
		setShow(false)
		setSelecting(0)
		aux.setHours(0)

		TimelogNS.create({
			client: client,
			start: add(aux, { minutes: 30 * start }),
			end: add(aux, { minutes: 30 * (end + 1) }),
			owner: currentUser!,
		})
	}

	const handleMouseMove = useCallback((e: MouseEvent) => {
		setEnd(getCurrentDate(e.pageY))
	}, [top])

	const getCurrentDate = (pos: number) => {
		return Math.floor((pos - top) / cellHeight)
	}

	const handleMouseUp = useCallback((e: MouseEvent) => {
		setEnd(getCurrentDate(e.pageY))
		setShow(true)
		setSelecting(2)
	}, [top])

	const cellHeight = useMemo(() => (3.5 * parseFloat(getComputedStyle(document.documentElement).fontSize)), [])

	useEffect(() => {
	    if (selecting === 1) {
			window.addEventListener('mousemove', handleMouseMove)
			window.addEventListener('mouseup', handleMouseUp)
	    } else {
			window.removeEventListener('mousemove', handleMouseMove)
			window.removeEventListener('mouseup', handleMouseUp)
		}
	    return () => {
			window.removeEventListener('mousemove', handleMouseMove)
			window.removeEventListener('mouseup', handleMouseUp)
		}
	}, [selecting, handleMouseMove, handleMouseUp])

	useEffect(() => {
		setTop(calendarClickRef.current?.offsetTop!)
	}, [calendarClickRef])

	return (
		<div className='my-10'>
			<Toolbar currentDate={currentDate} setCurrentDate={setCurrentDate}/>
			<div className='flex rounded-lg border-2 border-gray-200'>
				<div className='border-r-2 border-gray-200'>
					<div className='p-4 h-14'>
						<AccessAlarmIcon />
					</div>
					{[...Array(48).keys()].map(value => {
						const hour = Math.floor(value / 2)
						const minute = (value % 2 * 30).toString().padStart(2, '0')
						return (
							<div key={value} className='text-center border-t-2 border-gray-200 py-4 h-14'>{hour}:{minute}</div>
						)
					})}
				</div>
				<div className='w-full'>
					<div className='h-14 text-center align-middle p-4 font-semibold uppercase'></div>
					<div
						className='cursor-default relative'
						onMouseDown={e => {
							setTop(calendarClickRef.current?.offsetTop!)
						}}
						onDragStart={e => e.preventDefault()}
						ref={calendarClickRef}
					>

						<div className='absolute w-full'>
							{(selecting === 1 || selecting === 2) &&
									<div className='absolute w-full p-2' style={{ height: `${(end - start + 1) * 3.5}rem`, top: `${start * cellHeight}px` }}>
										<div className='rounded-md bg-blue-500 shadow-md w-full h-full'></div>
									</div>
							}
							{timelogs
								.filter(timelog => currentDate.toDateString() === timelog.start.toDateString())
								.map(timelog => {
									const start = timelog.start
									const end = timelog.end
									const top = ((start.getHours()) * 2 + (start.getMinutes() / 30)) * cellHeight
									const height = Math.round((end.getTime() - start.getTime()) / 1000 / 60) / 30 * 3.5
									return (
										<button
											key={timelog.id}
											className='absolute w-full p-2' style={{ height: `${height}rem`, top: `${top}px` }}
											onClick={e => handleEventClick(timelog)}
										>
											<div className='rounded-md bg-blue-500 text-white px-4 py-2 shadow-md w-full h-full'>{timelog.client.name} ({timelog.client.nickname})</div>
										</button>
									)
								})}
						</div>
						{[...Array(48).keys()].map(value => (
							<div
								key={value}
								onMouseDown={(e) => {
									setStart(getCurrentDate(e.pageY))
									setEnd(getCurrentDate(e.pageY))
									setSelecting(1)
								}}
								className='text-center border-t-2 border-gray-200 py-4 h-14'
							></div>
						))}
					</div>
				</div>
			</div>
			<Modal
				open={showEditing}
				onClose={() => setShowEdition(false)}
			>
				<Stack spacing={1} sx={{
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					border: '2px solid #000',
					boxShadow: 24,
					p: 4,
				}}>
					<Autocomplete
						disableClearable
						id='disable-portal'
						options={startsTimes}
						isOptionEqualToValue={(option, value) => option.hour === value.hour}
						getOptionLabel={option => option.hour}
						onChange={(e, value) => value ? handleUpdateInitalHour(value?.id) : ''}
						renderInput={(params) => (
							<TextField {...params} label='Horário de Inicio' />
						)}

					/>
					<Autocomplete
						disableClearable
						id='disable-portal'
						options={startsTimes}
						isOptionEqualToValue={(option, value) => option.hour === value.hour}
						getOptionDisabled={(option) =>
							option.id < startChange
						}
						getOptionLabel={(option) => option.hour}
						onChange={(e, value) => value ? handleUpdateEndHour(value?.id) : ''}
						renderInput={(params) => (
							<TextField {...params} label='Horário de término' />
						)}

					/>
					{timelogUnique && (
						<div className='w-full flex justify-between gap-2'>
							<button className='bg-blue-iest text-white py-2 rounded flex-1' onClick={handleUpdateTimelog}>Salvar</button>
							<button className='bg-blue-iest text-white py-2 rounded flex-1' onClick={e => handleClickButtonDelete(timelogUnique)}>Deletar</button>
						</div>
					) }
				</Stack>
			</Modal>

			<Modal
				open={show}
				onClose={() => setShow(false)}
			>
				<Autocomplete
					disablePortal
					id='combo-box-demo'
					options={clients}
					isOptionEqualToValue={(option, value) => option.name === value.name}
					getOptionLabel={option => `${option.id}_${option.nickname}`}
					onChange={(e, value) => handleAutocompleteChange(value)}
					sx={{
						position: 'absolute' as 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
					}}
					renderInput={(params: any) => <TextField {...params} label='Cliente' />}
				/>
			</Modal>
		</div>
	)
}

export default TimelogPage
