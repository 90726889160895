import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentSnapshot, onSnapshot, PartialWithFieldValue, query, setDoc, Timestamp } from 'firebase/firestore'
import { firestore } from 'shared/firebase'

export interface FirestorePost {
	content: string
	date_created: Timestamp
	date_updated: Timestamp
	title: string
	imageURL: string
}
export interface Post {
    id: string
    content: string
    date_created: Date
    date_updated: Date
    title: string
    imageURL: string
}

export const postsCollection = collection(firestore, 'posts') as CollectionReference<FirestorePost>

function fromFirestore(docSnap: DocumentSnapshot<FirestorePost>): Post {
	const data = docSnap.data()
	if (data) {
		return {
			...data,
			content: data.content || '',
			id: docSnap.id,
			date_created: data.date_created.toDate(),
			date_updated: data.date_updated.toDate(),
		}
	} else {
		throw new Error('Can\'t find id')
	}
}

function toTimestamp(date?: Date): Timestamp | undefined {
	return date ? Timestamp.fromDate(date) : undefined
}

function toFirestore(post: Partial<Post>): PartialWithFieldValue<FirestorePost> {
	return {
		...post.content && { content: post.content },
		...post.date_created && { date_created: toTimestamp(post.date_created) },
		...post.date_updated && { date_updated: toTimestamp(post.date_updated) },
		...post.title && { title: post.title },
		...post.imageURL && { imageURL: post.imageURL },
	}
}

export namespace PostNS {
    export function create(values: Partial<Post>) {
    	return addDoc(postsCollection, toFirestore(values))
    }

    export function get(id: string, cb: (data: Post) => void) {
    	return onSnapshot(doc(postsCollection, id), docSnap => {
    		cb(fromFirestore(docSnap))
    	})
    }
    export function list(cb: (data: Post[]) => void) {
    	return onSnapshot(query(postsCollection), snapshot => {
    		cb(snapshot.docs.map(docSnap => fromFirestore(docSnap)))
    	})
    }
    export function update(id: string, data: Partial<Post>) {
    	return setDoc(doc(postsCollection, id), toFirestore(data), { merge: true })
    }
	export function remove(id: string) {
		return deleteDoc(doc(postsCollection, id))
	}
}