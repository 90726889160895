import { collection, CollectionReference, doc, DocumentSnapshot, getDoc, getDocs, onSnapshot, orderBy, PartialWithFieldValue, query, setDoc, Timestamp } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { authFirestore, authFunctions } from 'shared/firebase'
import { ScheduleData } from 'shared/ScheduleData'

export interface FirestoreUser {
	birthday: Timestamp
	company: string
	department: string
	displayName: string
	email: string
	password: string
	fullName: string
	moderationTeam: string
	personalEmail: string
	photoURL: string
	admin?: boolean
	schedule: string[]
	wechat: string
	period: string
}

export const moderationTeams = [
	'ADM',
	'Eco 1',
	'Eco 2',
	'Eco 2 Noturno',
	'Eco 3',
	'Eco 4',
	'Lives',
	'Mansão X Espanhol',
	'Mansão X Português',
	'QA',
	'Top Comment',
	'Operações de Notícias',
	'Copa do Mundo',
	'',
] as const

export const periods = [
	'Manhã',
	'Tarde',
	'Madrugada',
	'Integral',
] as const

export const departments = [
	'Moderação de Conteúdo',
	'E-Commerce',
	'Recursos Humanos',
	'R&S',
	'DP',
	'Alibaba',
	'Edição de Vídeos',
	'Marketing',
	'TI',
	'Administrativo',
	'Contábil',
	'Paralegal',
	'Fiscal',
	'Diretoria',
	'',
] as const

export interface User {
	id: string
	birthday: Date
	company: string
	department: string
	period: string
	displayName: string
	fullName: string
	email: string
	password: string
	personalEmail: string
	photoURL: string
	admin?: boolean
	wechat: string
	moderationTeam: string
	schedule: ScheduleData
}

export const usersCollection = collection(authFirestore, 'users') as CollectionReference<FirestoreUser>

function fromFirestore(docSnap: DocumentSnapshot<FirestoreUser>): User {
	const data = docSnap.data()

	if (data) {
		const user: User = {
			...data,
			id: docSnap.id,
			company: data.company,
			period: data.period,
			department: data.department,
			moderationTeam: data.moderationTeam,
			schedule: new ScheduleData(data.schedule),
			birthday: data.birthday.toDate(),
		}
		return user
	} else {
		throw new Error('Can\'t find id ' + docSnap.id)
	}
}

function toTimestamp(date?: Date): Timestamp | undefined {
	return date ? Timestamp.fromDate(date) : undefined
}
function toFirestore(user: Partial<User>): PartialWithFieldValue<FirestoreUser> {
	return {
		...user.birthday && { birthday: toTimestamp(user.birthday) },
		...user.company && { company: user.company },
		...user.department && { department: user.department },
		...user.displayName && { displayName: user.displayName },
		...user.email && { email: user.email },
		...user.password && { password: user.password },
		...user.fullName && { fullName: user.fullName },
		...user.personalEmail && { personalEmail: user.personalEmail },
		...user.photoURL && { photoURL: user.photoURL },
		...user.admin && { admin: user.admin },
		...user.wechat && { wechat: user.wechat },
		...user.moderationTeam && { moderationTeam: user.moderationTeam },
		...user.schedule && { schedule: user.schedule.toFirestore() },
		...user.period && { period: user.period },
	}
}

export namespace UserNS {
    export function create(values: Partial<User>) {
    	const createUser = httpsCallable(authFunctions, 'createUser')
    	return createUser(values)
    }

    export async function getSync(id: string) {
    	const docSnap = await getDoc(doc(usersCollection, id))
    	return fromFirestore(docSnap)
    }

    export function get(id: string, cb: (data: User) => void) {
    	return onSnapshot(doc(usersCollection, id), docSnap => {
    		cb(fromFirestore(docSnap))
    	})
    }
    export function list(cb: (data: User[]) => void) {
    	return onSnapshot(query(usersCollection, orderBy('displayName', 'asc')), snapshot => {
    		cb(snapshot.docs.map(docSnap => fromFirestore(docSnap)))
    	})
    }
    export async function listSync(cb: (data: User[]) => void) {
    	const snapshot = await getDocs(query(usersCollection, orderBy('displayName', 'asc')))
    	cb(snapshot.docs.map(docSnap => fromFirestore(docSnap)))
    }
    export function update(id: string, data: Partial<User>) {
    	return setDoc(doc(usersCollection, id), toFirestore(data), { merge: true })
    }
	export function remove(id: string) {
		const deleteUser = httpsCallable(authFunctions, 'deleteUser')
		return deleteUser({ id })
	}
}