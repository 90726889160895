import React from 'react'
import Birthday from './Birthday'
import LatestPost from './LatestPost'
import BG from 'assets/woman-in-blue-crew-neck-shirt-using-black-laptop-computer-3994051.png'
import Wave from 'assets/blue-background.png'

const Home = () => {
  return (
    <div className='w-full relative'>
      <div className='w-full absolute -z-20'>
        <img
          className='w-full opacity-40 h-2/3 hidden md:block md:absolute'
          src={BG}
          alt='fundo do cabecalho'
        />
        <img
          className='w-full h-full hidden md:block'
          src={Wave}
          alt='parte de baixo do cabecalho'
        />
      </div>
      <div className='flex w-full flex-wrap p-10 pt-20'>
        <div className='w-full flex flex-col lg:flex-1 lg:w-3/4 lg:m-2'>
          <LatestPost />
        </div>
        <div className='w-full lg:w-1/4 xl:m-2'>
          <Birthday />
        </div>
      </div>
    </div>
  )
}

export default Home
