import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Lgb from "assets/iest_logo-Branco.png";
// import '../index.css'
import { Menu, MenuItem } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { authApp } from "shared/firebase";
import { useAuth } from "shared/auth";
import { HiOutlineX, HiMenu } from "react-icons/hi";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import "../assets/animation.css";
import { log } from "console";

const HeaderContext = createContext<{
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  setMenuOpen: () => {},
});

const useHeader = () => useContext(HeaderContext);

interface NavLinkProps {
  children: React.ReactNode;
  to: string;
  setDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}
const HeaderLink = ({ children, to, setDropdown }: NavLinkProps) => {
  const { setMenuOpen } = useHeader();
  return (
    <li className="flex">
      <NavLink
        className="text-white py-2 md:px-4 text-lg w-full hover:bg-iest-accent"
        to={to}
        onClick={(e) => {
          setMenuOpen(false);
          setDropdown(false);
        }}
      >
        {children}
      </NavLink>
    </li>
  );
};

const CurrentUser = () => {
  const { setMenuOpen } = useHeader();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const handleSignout: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const auth = getAuth(authApp);
    signOut(auth).then(() => {
      location.reload();
    });
  };

  const [userAnchor, setUserAnchor] = useState<null | HTMLElement>(null);
  const [dropdown, setDropdown] = useState(false);

  const hasFullAccess = currentUser?.admin || currentUser?.department === "TI";
  const canSeeModerationAdmin =
    (currentUser?.department === "Moderação de Conteúdo" &&
      currentUser?.moderationTeam === "ADM") ||
    hasFullAccess;
  const canSeeTimelogAdmin =
    currentUser?.fullName === "Nelson Svanci" ||
    currentUser?.fullName === "Samuel da Silva Camargo" ||
    hasFullAccess;
  const canSeeAdmin =
    hasFullAccess || canSeeModerationAdmin || canSeeTimelogAdmin;
  const canSeeConverter =
    currentUser?.department === "Fiscal" ||
    currentUser?.department === "DP" ||
    hasFullAccess;
  const canSeeBenefits = currentUser?.period != "Madrugada";

  return (
    <>
      <ul className="flex justify-center items-center flex-col relative md:flex-row">
        <li
          onClick={() => setDropdown(!dropdown)}
          className="md:flex text-lg text-white md:justify-center md:items-center cursor-pointer hidden hover:bg-iest-accent"
        >
          <p className="flex justify-center items-center py-2 md:px-4">
            Informações
            {dropdown ? (
              <AiOutlineArrowUp className="ml-2 hidden md:block" />
            ) : (
              <AiOutlineArrowDown className="ml-2 hidden md:block" />
            )}
          </p>
        </li>
        {(dropdown || window.innerWidth <= 758) && (
          <ul className=" bg-blue-iest pb-2  z-20 flex flex-col justify-center items-center md:block dropdown-animation md:rounded-b md:absolute md:left-[-13px] md:top-[63px]">
            {canSeeBenefits && (
              <HeaderLink setDropdown={setDropdown} to="/beneficios">
                Beneficios
              </HeaderLink>
            )}
            <HeaderLink setDropdown={setDropdown} to="/sites">
              Sites
            </HeaderLink>
            <HeaderLink setDropdown={setDropdown} to="/politicas">
              Politicas
            </HeaderLink>
            <HeaderLink setDropdown={setDropdown} to="/manual">
              Manual
            </HeaderLink>
            <HeaderLink setDropdown={setDropdown} to="/contatos">
              Contatos
            </HeaderLink>
            <HeaderLink setDropdown={setDropdown} to="/nossos-valores">
              Nossos valores
            </HeaderLink>
          </ul>
        )}
        <HeaderLink setDropdown={setDropdown} to="/comunicados">
          Comunicados
        </HeaderLink>
        {canSeeConverter && (
          <HeaderLink setDropdown={setDropdown} to="/ferramentas">
            Ferramentas
          </HeaderLink>
        )}
        {canSeeAdmin && (
          <div className="hidden md:block">
            <HeaderLink setDropdown={setDropdown} to="/admin">
              Admin
            </HeaderLink>
          </div>
        )}
      </ul>

      {currentUser && (
        <div className="flex ml-0 flex-col items-center justify-center md:ml-auto md:flex-row">
          <div className="py-2 h-full">
            <button
              className="h-24 md:h-full rounded-full overflow-hidden border-2 border-iest-primary aspect-square"
              onClick={(event) => setUserAnchor(event.currentTarget)}
            >
              <img className="" src={currentUser.photoURL} />
            </button>
          </div>
          <div className="md:ml-4 py-2 flex flex-col whitespace-nowrap text-white">
            <div className="text-xl">{currentUser.displayName}</div>
            <div className="text-base text-gray-300 text-center">
              {currentUser.department}
            </div>
          </div>
        </div>
      )}

      <Menu
        className="text-xl"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={userAnchor}
        open={Boolean(userAnchor)}
        onClose={() => setUserAnchor(null)}
      >
        <MenuItem>
          <NavLink to="/profile" className="w-full">
            Perfil
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/apontamento" className="w-full">
            Apontamento
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/escala" className="w-full">
            Escala
          </NavLink>
        </MenuItem>
        {/* <MenuItem>
						<Calendario />
					</MenuItem> */}
        <MenuItem>
          <button className="w-full h-full text-left" onClick={handleSignout}>
            Sair
          </button>
        </MenuItem>
      </Menu>
    </>
  );
};

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    menuOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [menuOpen]);

  return (
    <HeaderContext.Provider value={{ setMenuOpen }}>
      <div className="relative w-full bg-header-mobile bg-blue-iest">
        <div className="w-full h-16 px-4 flex  justify-between z-10">
          <Link className="py-3 px-4" to="/">
            <img src={Lgb} alt="Logo IEST" className="h-full"></img>
          </Link>
          <div className="hidden w-full md:flex">
            <CurrentUser />
          </div>

          <div className="flex md:hidden">
            <button onClick={(e) => setMenuOpen(!menuOpen)}>
              {menuOpen === true ? (
                <HiOutlineX className="w-12 text-white text-4xl" />
              ) : (
                <HiMenu className="w-12 text-white text-4xl" />
              )}
            </button>
          </div>
        </div>

        {menuOpen && (
          <div className="fixed bg-blue-iest top-16 flex flex-col justify-between items-center z-10 right-0 left-0 bottom-0 menu-mobile-animation">
            <CurrentUser />
          </div>
        )}
      </div>
    </HeaderContext.Provider>
  );
};

export default Header;
