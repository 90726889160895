import { getDoc, doc, collection, setDoc } from 'firebase/firestore'
import { deleteObject, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { firestore, storage } from './firebase'

export async function getDocument(documentType: 'newspaper' | 'politics' | 'manual') {
	const snap = await getDoc(doc(collection(firestore, 'metadata'), documentType))
	return ref(storage, snap.data()!.filepath)
}


export async function updateDocument(file: File, documentType: 'newspaper' | 'politics' | 'manual') {
	return getDocument(documentType)
		.then(ref => deleteObject(ref))
		.then(() => uploadBytes(ref(storage, file.name), file))
		.then(snapshot => setDoc(
			doc(collection(firestore, 'metadata'), documentType),
			{ filePath: snapshot.metadata.name },
			{ merge: true },
		))
}


export async function uploadFile(file: File) {
	return uploadBytes(ref(storage, `converter/${file.name}`), file)
}


export function uploadImage(file: File, id: string) {
	return uploadBytes(ref(storage, `posts/${id}`), file)
}

export async function getNewspaper() {
	const ref = await getDocument('newspaper')
	return await getDownloadURL(ref)
}

export async function getPolitics() {
	const ref = await getDocument('politics')
	return await getDownloadURL(ref)
}

export async function getManual() {
	const ref = await getDocument('manual')
	return await getDownloadURL(ref)
}
