import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid'
import { ClientNS, Client } from 'services/clients'
import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'


const columns: GridColDef[] = [
	{ field: 'id', headerName: 'ID', width: 90 },
	{
		field: 'name',
		headerName: 'Nome',
		width: 600,
		editable: true,
	},
	{
		field: 'nickname',
		headerName: 'Nome abreviado',
		width: 150,
		editable: true,
	},
]
const initialState = {
	id: '',
	name: '',
	nickname: '',
}
const Clients = () => {

	const [clients, setClients] = useState<Client[]>([])
	const [open, setOpen] = useState(false)
	const [formData, setFormData] = useState<any>(initialState)

	const processRowUpdate = React.useCallback(async (client: GridRowModel<Client>) => {
		// Make the HTTP request to save in the backend
		ClientNS.update(client.id, client)
		return client
	}, [])

	useEffect(() => ClientNS.list(clients => setClients(clients)), [])

	const handleProcessRowUpdateError = React.useCallback((error: Error) => {
		console.error(error)
	}, [])
	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

		setFormData({ ...formData, [e.currentTarget.name]: e.currentTarget.value })
	}
	const handleSubmit = () => {
		const id = formData.id
		delete formData.id
		ClientNS.update(id, formData).then(() => {
			alert('Salvo com sucesso')
			setFormData(initialState)
			setOpen(false)
		}).catch(e => alert('Ocorreu um erro, contate a equipe de TI' + e))
	}
	return (
		<div className='h-full pb-4 flex flex-col'>
			<div className='mb-4'>
				<Button variant='contained' onClick={e => setOpen(true)}>Adicionar novo</Button>
			</div>
			<div className='px-4 py-3 flex bg-iest-primary text-white text-xl font-bold'>
				Clientes
			</div>
			<div className='bg-white px-4 py-3 w-full h-full'>
				<DataGrid
					rows={clients}
					columns={columns}
					pageSize={10}
					rowsPerPageOptions={[10]}
					checkboxSelection
					disableSelectionOnClick
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={handleProcessRowUpdateError}
					experimentalFeatures={{ newEditingApi: true }}
				/>
			</div>
			<Dialog onClose={e => setOpen(false)} open={open} sx={{ padding: '2rem' }}>
				<DialogTitle>Criar novo</DialogTitle>
				<DialogContent>
					<div className='flex flex-col space-y-4 p-2'>
						<TextField label='ID' value={formData['id']} onChange={handleChange} name='id'/>
						<TextField label='Nome' value={formData['name']} onChange={handleChange} name='name'/>
						<TextField label='Nome Abreviado' value={formData['nickname']} onChange={handleChange} name='nickname'/>
						<Button onClick={handleSubmit} variant='contained'>Salvar</Button>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	)
}

export default Clients