import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog } from "@mui/material";
import { getManual } from "shared/utils";
import Draw from "assets/Manual.png";
import { useAuth } from "shared/auth";
import { getDownload } from "services/documents";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { firestore, storage } from "shared/firebase";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { AiOutlineSearch } from "react-icons/ai";

export const sorocabaEmployees = [
  "Theodora Pietra Nascimento Da Silva",
  "Dandara Marie Silva Santos",
  "Luiz Eduardo Da Silva Passos",
  "Sara Simao Garcia Del Valle",
  "Tainara Dos Santos",
  "Jennifer Cristina De Souza Vieira",
  "Paloma Almeida Soares",
  "Amanda Alves De Oliveira Barbim",
  "Bryan Lucas Santos Matos",
  "Daiane Regina Martins Makhoul",
  "Enrique Da Silva Oliveira",
  "Maria Alice de Carvalho Sá Freire",
];

const Manual = () => {
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [openSecondary, setOpenSecondary] = useState(false);
  const [current, setCurrent] = useState("");
  const [pdfs, setPdfs] = useState<
    { name: string; url: string; permissions: string }[]
  >([]);
  const storage = getStorage();
  const db = getFirestore();

  useEffect(() => {
    getDocs(
      query(collection(firestore, "documents"), where("type", "==", "manual"))
    ).then((snap) => {
      Promise.all(
        snap.docs.map(async (doc) => ({
          permissions: doc.data().permissions,
          name: doc.data().name,
          url: await getDownloadURL(ref(storage, `documents/${doc.id}.pdf`)),
        }))
      ).then((pdfs: any[]) =>
        setPdfs(
          pdfs.filter(
            (pdf) =>
              pdf.permissions.length === 0 ||
              pdf.permissions.includes(currentUser?.department)
          )
        )
      );
    });
  }, []);

  const filteredPdfs = pdfs.filter((pdf) => {
    if (
      pdf.name === "IEST Manual dos colaboradores - Sorocaba" &&
      currentUser
    ) {
      return sorocabaEmployees.includes(currentUser.fullName);
    }
    return true;
  });

  console.log(filteredPdfs);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="lg:min-h-screen items-center p-8 sm:p-12">
        <div className="flex-grow">
          <h1 className="mt-4 text-blue-900 font-bold text-left text-2xl sm:text-5xl mb-2">
            Manual
          </h1>
          <p className="text-left text-blue-900 sm:text-lg">
            Entenda o manual do colaborador
          </p>
        </div>
        {/* <ul className='grid grid-cols-1 lg:grid-cols-2'>
					{manuals.map((manual, index) => (
						<li key={index} className='w-full'>
							<div className='hidden lg:inline-flex font-semibold mt-4 bg-white hover:bg-gray-50 text-gray-800 py-2 px-4 items-center border border-gray-400 rounded-xl cursor-pointer'
								onClick={e => {
									setDownloadURL(manual.url)
									setOpen(true)
								}}
							>
								{manual.name}
							</div>
							<a className='inline-flex lg:hidden font-semibold mt-4 bg-white hover:bg-gray-50 text-gray-800 py-2 px-4 items-center border border-gray-400 rounded-xl cursor-pointer'
								href={manual.url}
								type='application/pdf'
								download
								target='_blank'
							>
								{manual.name}
							</a>
						</li>
					))}
				</ul>

				<Dialog
					fullWidth={true}
					maxWidth='xl'
					onClose={() => setOpen(false)} open={open}>
					{downloadURL === ''
						? <div className='p-4'><CircularProgress /></div>
						: <embed type='application/pdf' src={downloadURL} width='100%' height='1000'/>
					}
				</Dialog> */}
        {filteredPdfs.length === 0 ? (
          <CircularProgress />
        ) : (
          <button className="mt-8 bg-white hover:bg-gray-100 text-gray-800 font-bold inline-flex items-center border border-gray-400 rounded-xl">
            <div className="py-2 px-4" onClick={() => setOpen(true)}>
              Manuais
            </div>
          </button>
        )}

        <Dialog
          fullWidth={true}
          maxWidth="xl"
          onClose={() => setOpen(false)}
          open={open}
        >
          <div className="p-10">
            <div className="text-lg font-bold">Selecione o PDF</div>
            <div className="grid grid-cols-3 gap-4">
              {filteredPdfs.map((pdf) => (
                <div
                  key={pdf.name}
                  className="p-4 rounded-lg border border-black hover:bg-gray-100 cursor-pointer"
                  onClick={(e) => {
                    setOpen(false);
                    setOpenSecondary(true);
                    setCurrent(pdf.url);
                  }}
                >
                  <AiOutlineSearch />
                  {pdf.name}
                </div>
              ))}
            </div>
          </div>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="xl"
          onClose={() => setOpenSecondary(false)}
          open={openSecondary}
        >
          {current === "" ? (
            <CircularProgress />
          ) : (
            <embed
              type="application/pdf"
              src={current}
              width="100%"
              height="1000"
            />
          )}
        </Dialog>
      </div>
      <div className="lg:min-h-screen lg:flex lg:items-center">
        <img src={Draw} alt="Desenho" />
      </div>
    </div>
  );
};

export default Manual;
