import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentSnapshot, onSnapshot, PartialWithFieldValue, query, setDoc, Timestamp } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { getDownloadURL, ref } from 'firebase/storage'
import { authFunctions, firestore, storage } from 'shared/firebase'

export interface FirestoreBenefit {
	name: string
	text: string
	type: string
	url: string
}
export interface Benefit {
	id: string
    logo: string
    name: string
    text: string
    type: string
    url: string
}

export const benefitsCollection = collection(firestore, 'benefits') as CollectionReference<Benefit>

async function fromFirestore(docSnap: DocumentSnapshot<FirestoreBenefit>) {
	const data = docSnap.data()
	if (data) {
		const logo = await getDownloadURL(ref(storage, `benefit_logos/${docSnap.id}.png`))

		return {
			...data,
			id: docSnap.id,
			logo,
		}
	} else {
		throw new Error('Can\'t find id')
	}
}


function toFirestore(benefit: Partial<Benefit>): PartialWithFieldValue<FirestoreBenefit> {
	return { ...benefit }
}

export namespace BenefitNS {
    export function create(values: Partial<Benefit>) {
    	return addDoc(benefitsCollection, toFirestore(values))
    }

    // export function get(id: string, cb: (data: Benefit) => void) {
    // 	return onSnapshot(doc(benefitsCollection, id), docSnap => {
    // 		cb(fromFirestore(docSnap))
    // 	})
    // }
    export function list(cb: (data: Benefit[]) => void) {
    	return onSnapshot(query(benefitsCollection), snapshot => {
    		Promise.all(snapshot.docs.map(docSnap => fromFirestore(docSnap)))
    			.then(data => cb(data))
    	})
    }
    export function update(id: string, data: Partial<Benefit>) {
    	return setDoc(doc(benefitsCollection, id), toFirestore(data), { merge: true })
    }
	export function remove(id: string) {
		return deleteDoc(doc(benefitsCollection, id))
	}
}