import React, { FC, useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface Props {
  title: string
  children: JSX.Element[] | JSX.Element
}

const ExpandMenu: FC<Props> = ({ children, title }) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <button className='block' onClick={() => setOpen((open) => !open)}>
        <div className='text-lg font-semibold inline hover:bg-gray-300 rounded'>
          {title}
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </button>
      <div className='mx-4 mt-2 flex flex-col space-y-4 '>
        {open && children}
      </div>
    </div>
  )
}

export default ExpandMenu
