import React, { useMemo, useState } from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getDaysInMonth, set, startOfMonth } from "date-fns";
import { useAuth } from "shared/auth";
import { User, UserNS } from "services/user";
import { StatusValue } from "shared/ScheduleData";

const UserView = ({
  user,
  date,
  handleClick,
  selectedStatus,
  setSelectedStatus,
  cells,
}: {
  user: User | null;
  date: Date | null;
  handleClick: (user: User, cell: Date) => void;
  selectedStatus: StatusValue;
  setSelectedStatus: React.Dispatch<React.SetStateAction<StatusValue>>;
  cells: Date[];
}) => {
  const prefixDays = useMemo(() => {
    if (!date) return 0;
    const res = startOfMonth(date).getDay() - 1;
    if (res === -1) return 6;
    else return res;
  }, [date]);
  const { currentUser } = useAuth();
  const canSeeModeration = currentUser?.department === "Moderação de Conteúdo";

  const bg = (index: number) => {
    if (!date) return;
    const status = user?.schedule?.getStatus(date.getMonth(), index + 1);
    if (status === "folga") {
      return "bg-yellow-600";
    } else if (status === "outros") {
      return "bg-blue-600";
    } else if (status === "ferias") {
      return "bg-emerald-200";
    } else if (status === "trabalho") {
      return "bg-gray-200";
    } else if (status === "trabalhoP") {
      return "bg-red-600";
    } else if (status === "trabalhoS") {
      return "bg-yellow-300";
    } else if (status === "trabalhoB") {
      return "bg-pink-600";
    } else if (status === "feriado") {
      return "bg-violet-600";
    }
  };
  return (
    <div className="w-full">
      <div className="flex my-4 space-x-2 ml-4">
        <div
          className={`rounded-md w-6 h-6 bg-yellow-600 ${
            selectedStatus === "folga"
              ? "border-2 border-gray-900"
              : "border border-gray-400"
          }`}
          onClick={() => setSelectedStatus("folga")}
        ></div>
        <p className="text-base">Folga</p>
        <div
          className={`rounded-md w-6 h-6 bg-emerald-600 ${
            selectedStatus === "ferias"
              ? "border-2 border-gray-900"
              : "border border-gray-400"
          }`}
          onClick={() => setSelectedStatus("ferias")}
        ></div>
        <p className="text-base">Férias</p>
        <div
          className={`rounded-md w-6 h-6 bg-white ${
            selectedStatus === "trabalho"
              ? "border-2 border-gray-900"
              : "border border-gray-400"
          }`}
          onClick={() => setSelectedStatus("trabalho")}
        ></div>
        <p className="text-base">Trabalho</p>
        <div
          className={`rounded-md w-6 h-6 bg-red-600 ${
            selectedStatus === "trabalhoP"
              ? "border-2 border-gray-900"
              : "border border-gray-400"
          }`}
          onClick={() => setSelectedStatus("trabalhoP")}
        ></div>
        <p className="text-base">Trabalho Paraiso</p>
        {canSeeModeration && (
          <>
            <div
              className={`rounded-md w-6 h-6 bg-pink-600 ${
                selectedStatus === "trabalhoB"
                  ? "border-2 border-gray-900"
                  : "border border-gray-400"
              }`}
              onClick={() => setSelectedStatus("trabalhoB")}
            ></div>
            <p className="text-base">Trabalho Barueri</p>
            <div
              className={`rounded-md w-6 h-6 bg-yellow-300 ${
                selectedStatus === "trabalhoS"
                  ? "border-2 border-gray-900"
                  : "border border-gray-400"
              }`}
              onClick={() => setSelectedStatus("trabalhoS")}
            ></div>
            <p className="text-base">Trabalho Sorocaba</p>
          </>
        )}
        <div
          className={`rounded-md w-6 h-6 bg-violet-600 ${
            selectedStatus === "feriado"
              ? "border-2 border-gray-900"
              : "border border-gray-400"
          }`}
          onClick={() => setSelectedStatus("feriado")}
        ></div>
        <p className="text-base">Feriado</p>
        <div
          className={`rounded-md w-6 h-6 bg-blue-600 ${
            selectedStatus === "outros"
              ? "border-2 border-gray-900"
              : "border border-gray-400"
          }`}
          onClick={() => setSelectedStatus("outros")}
        ></div>
        <p className="text-base">Outros</p>
      </div>
      {date && user && user.schedule && (
        <div className="grid grid-cols-7 bg-white px-4 py-3">
          {["SEG", "TER", "QUA", "QUI", "SEX", "SAB", "DOM"].map((index) => (
            <div key={index} className="px-4 m-1 text-center">
              {index}
            </div>
          ))}
          {[...Array(prefixDays)].map((index) => (
            <div key={index} className="px-4 py-3 m-1"></div>
          ))}
          {[...Array(getDaysInMonth(date))].map((_, index) => (
            <div
              key={index}
              className={`px-4 py-1 rounded-md shadow-md text-white m-1 ${bg(
                index
              )}`}
              onMouseDown={(e) => {
                if (e.buttons === 1) {
                  handleClick(
                    user,
                    set(new Date(), { date: index + 1, month: date.getMonth() })
                  );
                }
              }}
            >
              <div className="pb-1 text-sm cursor-default">{index + 1}</div>
              <div className="py-4"></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserView;
