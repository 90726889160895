import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionSummary, AccordionDetails, Box, Tab, Tabs, CircularProgress } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { UserNS, User, moderationTeams, departments } from 'services/user'


const Contacts = () => {
	const [users, setUsers] = useState<User[]>([])
	const [value, setValue] = React.useState('')

	useEffect(() => UserNS.list(users => setUsers(users)), [])

	useEffect(() => {
		setValue(departments[0])
	}, [])

	const columns: GridColDef[] = [
		{
			field: 'displayName',
			headerName: 'Nome',
			width: 600,
		}, {
			field: 'email',
			headerName: 'Email',
			width: 350,
		}, {
			field: 'department',
			headerName: 'Departamento',
			width: 250,
		}, {
			field: 'moderationTeam',
			headerName: 'Equipe',
			width: 250,
		},
	  ]

	return (
		<div className='h-screen p-10 w-full'>
			{users.length === 0 && <CircularProgress/>}
			<DataGrid
				rows={users}
				columns={columns}
				pageSize={10}
				rowsPerPageOptions={[5]}
				disableSelectionOnClick
			/>
		</div>
	)
}

export default Contacts