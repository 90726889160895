import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getDaysInMonth, startOfMonth } from "date-fns";
import { useAuth } from "shared/auth";
import { User, UserNS } from "services/user";

const Schedule = () => {
  const [users, setUsers] = useState<User[]>([]);

  const { currentUser } = useAuth();
  const canSeeModeration = currentUser?.department === "Moderação de Conteúdo";
  const [selected, setSelected] = React.useState<Date | null>(new Date());
  const prefixDays = (selected: Date) => {
    const res = startOfMonth(selected).getDay() - 1;
    if (res === -1) return 6;
    else return res;
  };
  useEffect(() => {
    UserNS.listSync((users) =>
      setUsers(
        users.filter((user) => user.department === "Moderação de Conteúdo")
      )
    );
  }, []);

  const filteredUsersAdm = users?.filter((user) => {
    if (
      user.displayName === "Aniely Silva" ||
      user.displayName === "Heloisa Maria"
    ) {
      return user;
    }
  });

  const bg = (index: number) => {
    if (!selected) return;
    const status = currentUser?.schedule?.getStatus(
      selected.getMonth(),
      index + 1
    );
    if (status === "folga") {
      return "bg-yellow-600";
    } else if (status === "outros") {
      return "bg-blue-600";
    } else if (status === "ferias") {
      return "bg-emerald-600";
    } else if (status === "trabalho") {
      return "bg-gray-300";
    } else if (status === "trabalhoP") {
      return "bg-red-600";
    } else if (status === "trabalhoS") {
      return "bg-yellow-300";
    } else if (status === "trabalhoB") {
      return "bg-pink-600";
    } else if (status === "feriado") {
      return "bg-violet-500";
    }
  };

  const adminOn = (index: number) => {
    if (!selected) return;

    return filteredUsersAdm
      ?.map((userAdm) => {
        const userSchedule = userAdm?.schedule
          ?.getStatus(selected.getMonth(), index + 1)
          .toString();
        if (
          userSchedule === "trabalho" ||
          userSchedule === "trabalhoP" ||
          userSchedule === "trabalhoS" ||
          userSchedule === "trabalhoB"
        ) {
          return userAdm.displayName.split("")[0];
        }
      })
      .join(" ");
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center p-3">
      <div className="mb-4 w-full">
        <DatePicker
          label="Mês"
          value={selected}
          onChange={(newValue) => setSelected(newValue)}
          renderInput={(params) => <TextField {...params} />}
          views={["month", "year"]}
        />
      </div>
      {selected && currentUser && currentUser.schedule && (
        <div className="w-full grid grid-cols-7 bg-white px-4 py-3">
          {["SEG", "TER", "QUA", "QUI", "SEX", "SAB", "DOM"].map((index) => (
            <div key={index} className="px-4 m-1 text-center">
              {index}
            </div>
          ))}
          {[...Array(prefixDays(selected))].map((index) => (
            <div key={index} className="px-4 py-3 m-1"></div>
          ))}
          {[...Array(getDaysInMonth(selected))].map((val, index) => (
            <div
              key={index}
              className={`px-4 py-1 rounded-md shadow-md text-white m-1 ${bg(
                index
              )} hover:brightness-75`}
            >
              <div className="pb-1 text-sm cursor-default">{index + 1}</div>
              {currentUser.period != "Madrugada" && (
                <div>
                  {canSeeModeration && (
                    <p className="text-sm w-4">{`${adminOn(index)}`}</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {canSeeModeration && (
        <div className="w-full px-6">
          <p>A = Aniely da Silva Santos</p>
          <p>H = Heloisa Maria Lopes Silva</p>
        </div>
      )}
      <div className="w-full flex my-4 space-x-2 ml-4">
        <div className="rounded-md w-6 h-6 bg-yellow-600"></div>
        <p className="text-base">Folga</p>
        <div className="rounded-md w-6 h-6 bg-emerald-600"></div>
        <p className="text-base">Férias</p>
        <div className="rounded-md w-6 h-6 bg-gray-300 border"></div>
        <p className="text-base">Trabalho</p>
        <div className="rounded-md w-6 h-6 bg-red-600"></div>
        <p className="text-base">Trabalho Paraiso</p>
        {canSeeModeration && (
          <>
            <div className="rounded-md w-6 h-6 bg-pink-600"></div>
            <p className="text-base">Trabalho Barueri</p>
            <div className="rounded-md w-6 h-6 bg-yellow-300"></div>
            <p className="text-base">Trabalho Sorocaba</p>
          </>
        )}

        <div className="rounded-md w-6 h-6 bg-violet-600"></div>
        <p className="text-base">Feriado</p>
        <div className="rounded-md w-6 h-6 bg-blue-600"></div>
        <p className="text-base">Outros</p>
      </div>
    </div>
  );
};

export default Schedule;
