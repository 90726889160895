import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import MultiSelect from "components/MultiSelect";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { deleteObject, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineFileAdd,
  AiOutlinePlus,
} from "react-icons/ai";
import { DepartmentNS } from "services/departments";

interface IFile {
  id: string;
  name: string;
  permissions: string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Manuals = () => {
  const [departments, setDepartments] = useState<string[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const db = getFirestore();
  const storage = getStorage();
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (!e.target.files) return;
    setLoading(true);
    const file = e.target.files[0];
    uploadBytes(ref(storage, `documents/${id}.pdf`), file)
      .then(() => {
        alert("Documento atualizado");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getDocs(
      query(collection(db, "documents"), where("type", "==", "manual"))
    ).then((query) => {
      setFiles(
        query.docs
          .sort(
            (first, second) =>
              Number(first.data().createdAt) - Number(second.data().createdAt)
          )
          .map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            permissions: doc.data().permissions,
          }))
      );
    });
  }, []);
  useEffect(() => {
    DepartmentNS.list((departments) =>
      setDepartments(departments.map((department) => department.name))
    );
  }, []);

  return (
    <div>
      <ul className="p-4 space-y-4">
        {files.map((file) => (
          <li key={file.id} className="flex items-center w-full">
            <TextField
              fullWidth
              value={file.name}
              onChange={(e) => {
                setFiles(
                  files.map((f) =>
                    f.id === file.id ? { ...f, name: e.currentTarget.value } : f
                  )
                );
                updateDoc(doc(db, "documents", file.id), {
                  name: e.currentTarget.value,
                });
              }}
            />

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
              <Select
                multiple
                value={file.permissions}
                onChange={(e) => {
                  const value = e.target.value;
                  const newValue =
                    typeof value === "string" ? value.split(",") : value;
                  setFiles(
                    files.map((f) =>
                      f.id === file.id
                        ? {
                            ...f,
                            permissions: newValue,
                          }
                        : f
                    )
                  );
                  updateDoc(doc(db, "documents", file.id), {
                    permissions: newValue,
                  });
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.length === departments.length ? (
                      <Chip label="Todos" />
                    ) : (
                      selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))
                    )}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {departments.length === file.permissions.length ? (
                  <MenuItem
                    onClick={() => {
                      setFiles(
                        files.map((f) =>
                          f.id === file.id
                            ? {
                                ...f,
                                permissions: [],
                              }
                            : f
                        )
                      );
                      updateDoc(doc(db, "documents", file.id), {
                        permissions: [],
                      });
                    }}
                  >
                    Deselecionar todos
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      setFiles(
                        files.map((f) =>
                          f.id === file.id
                            ? {
                                ...f,
                                permissions: departments,
                              }
                            : f
                        )
                      );
                      updateDoc(doc(db, "documents", file.id), {
                        permissions: departments,
                      });
                    }}
                  >
                    Selecionar todos
                  </MenuItem>
                )}
                {departments.map((department) => (
                  <MenuItem key={department} value={department}>
                    {department}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <label
              className={
                "ml-4 text-xl border border-gray-300 rounded-md p-2 " +
                (loading ? "text-gray-300" : "cursor-pointer")
              }
            >
              <input
                disabled={loading}
                type="file"
                hidden
                onChange={(e) => handleChange(e, file.id)}
                onClick={(e) => (e.currentTarget.value = "")}
              />
              <AiOutlineFileAdd />
            </label>
            <button
              className="ml-4 text-xl text-red-500 border border-gray-300 rounded-md p-2"
              onClick={() => {
                if (confirm("Você deseja mesmo deletar este documento?")) {
                  deleteDoc(doc(db, "documents", file.id)).then(() => {
                    deleteObject(ref(storage, `documents/${file.id}.pdf`));
                    setFiles([...files].filter((f) => f.id !== file.id));
                  });
                }
              }}
            >
              <AiOutlineDelete />
            </button>
          </li>
        ))}
        <li className="p-4">
          <button
            className="flex items-center"
            onClick={() => {
              addDoc(collection(db, "documents"), {
                name: "",
                type: "manual",
                createdAt: new Date(),
              }).then((docRef) => {
                setFiles([
                  ...files,
                  { id: docRef.id, name: "", permissions: [] },
                ]);
              });
            }}
          >
            <AiOutlinePlus />
            <div>Adicionar um novo</div>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Manuals;
