import { FirstPage } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { UserNS, User } from 'services/user'

const Birthday = () => {
	const [users, setUsers] = useState<User[]>([])

	users.sort(function(firstUserCompare, secondUserCompare) {
		return firstUserCompare.birthday.getDate() - secondUserCompare.birthday.getDate()
	})

	useEffect(() => UserNS.list(users => setUsers(users.filter(user =>
		user.birthday.getMonth() === new Date().getMonth(),
	))), [])

	return (

		<><div className='w-11/12 mx-auto p-3 mb-4 rounded-3xl bg-white flex flex-col shadow-md h-[700px]'>
			<h3 className='text-xl text-center border-b border-solid p-2 border-black font-black'>
				ANIVERSARIANTE DO MÊS 🎂
			</h3>
			<div className='flex p-2 widget-body overflow-y-auto'>
				<ul className='w-full flex flex-row flex-wrap content-start items-start'>
					{users.map((item, index) => (
						<li className='w-full p-4 h-14 list-none my-1 flex flex-row justify-between text-center' key={index}>
							<img className='w-8 h-8 rounded-full mr-1' src={item.photoURL} alt={'Aniversariante ' + (index + 1) + ' do mês'}></img>
							<div className='text-sm items-center flex-col'>{item.displayName}<div><div className='text-xs'>{item.department}</div></div></div>
							<div><span className='text-red-700 text-xs'>DIA:{item.birthday.getDate()}</span></div>
						</li>
					))}
				</ul>
			</div>
		</div>
		</>

	)
}

export default Birthday