import React, { useEffect, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { BenefitNS, Benefit } from 'services/benefits'

const Benefits = () => {
	const [benefits, setBenefits] = useState<Benefit[]>([])
	const [selected, setSelected] = useState<Benefit>()

	useEffect(() => BenefitNS.list(benefits => {
		setSelected(benefits[0])
		setBenefits(benefits)
	}), [])

	const handleClick = (benefit: Benefit) => {
		setSelected(benefit)
	}

	return (
		<div className='w-full items-center flex flex-col'>
			<div className='flex items-center space-x-12 xl:w-1/2 w-full mx-auto py-12'>
				<ul className='relative w-full whitespace-nowrap text-center transition-all overflow-x-scroll'>
					{benefits.map((item, index) => (
						<li key={index} className='transition-all aspect-square w-40 inline-block align-middle p-4' onClick={() => handleClick(item)}>
							<div className='w-full h-full border-2 rounded-full bg-white border-gray-400 overflow-hidden hover:shadow-lg transition-shadow'>
								<img className='object-contain w-full h-full' src={item.logo} alt='logo'/>
							</div>
						</li>
					))}
				</ul>
			</div>
			{selected && <div className='w-auto text-center px-8 py-6 bg-white rounded-3xl shadow-lg pb-10'>
				<div className='text-2xl font-bold mb-4'>
					{selected.name}
				</div>
				<div>
					{selected.text}
				</div>
				<div>
					Para mais informações, acesse <a className='font-semibold underline' href={selected.url}>aqui</a>
				</div>

			</div>}
			{/* <ul>
					{benefits.map((item, index) => (
						<li className=' border-solid border mb-4 py-3 px-4 rounded-3xl bg-white flex flex-col' key={index}>
							<div className='flex mb-2 items-center'>
								<span className='mr-2'><img className='w-36' src={item.logo} alt='logo'/></span>
								<span className='text-xl font-medium'>{item.name}</span>
							</div>
							<div>{item.text}</div>
						</li>
					))}
				</ul> */}
		</div>
	)
}

export default Benefits