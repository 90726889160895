import { TableRow, TableCell, TableBody, CircularProgress } from '@mui/material'
import {
  differenceInDays,
  endOfWeek,
  getDaysInMonth,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns'
import React, { useState, useEffect } from 'react'
import { User } from 'services/user'
import VisibilityIcon from '@mui/icons-material/Visibility'

interface BodyProps {
  cells: Date[]
  team: string
  users: User[]
  handleClick: (user: User, cell: Date) => void
  expanded: boolean
  triggerUserView: (user: User) => void
  // handleContextMenu: (event: React.MouseEvent, cell: Date, index: number, user: User) => void
}

const Body = ({
  cells,
  team,
  users,
  handleClick,
  expanded,
  triggerUserView,
}: BodyProps) => {
  const bg = (index: number, cell: Date, user: User) => {
    const firstWeek = startOfWeek(startOfMonth(cell), { weekStartsOn: 1 })
    const diff = differenceInDays(cell, firstWeek)
    const status = user.schedule?.getStatus(cell.getMonth(), cell.getDate())
    if (status === 'folga') {
      return 'bg-yellow-600'
    } else if (status === 'outros') {
      return 'bg-blue-600'
    } else if (status === 'ferias') {
      return 'bg-emerald-600'
    } else if (status === 'trabalhoP') {
      return 'bg-red-600'
    } else if (status === 'trabalhoB') {
      return 'bg-pink-600'
    } else if (status === 'trabalhoS') {
      return 'bg-yellow-300'
    } else if (status === 'feriado') {
      return 'bg-violet-600'
    } else if (index < 5) {
      return 'bg-gray-200'
    } else if (status === 'trabalho') {
      return 'bg-white'
    } else {
      return 'bg-gray-50'
    }
  }
  const bgPeriod: { [id in string]: string } = {
    Manhã: 'bg-morning',
    Tarde: 'bg-afternoon',
    Madrugada: 'bg-dawn',
    Integral: 'bg-white',
  }
  return (
    <TableBody>
      {users
        .filter(
          (user) => user.moderationTeam === team || user.department === team,
        )
        .map((user, userIndex) => (
          <TableRow key={userIndex}>
            {expanded ? (
              <TableCell
                sx={{
                  left: 0,
                  position: 'sticky',
                  padding: '0.375rem',
                  lineHeight: '1.75rem',
                  whiteSpace: 'nowrap',
                }}
                className={`${
                  bgPeriod[user.period ? user.period : 'Integral']
                }`}
              >
                {user.displayName}
                <VisibilityIcon
                  fontSize='small'
                  className='text-gray-500 ml-2 cursor-pointer'
                  onClick={() => {
                    triggerUserView(user)
                  }}
                />
              </TableCell>
            ) : (
              <TableCell
                sx={{
                  padding: '0.375rem',
                  whiteSpace: 'nowrap',
                  lineHeight: '1.75rem',
                }}
              >
                &nbsp;
              </TableCell>
            )}
            {cells.map((cell, index) => (
              <TableCell
                // onContextMenu={e => handleContextMenu(e, cell, index, user)}
                key={index}
                className={`select-none !p-0 border-l border-solid border-gray-200 text-white m-1 ${bg(
                  index,
                  cell,
                  user,
                )} hover:brightness-75`}
                onMouseDown={(e) => {
                  if (e.buttons === 1 && index >= 5) handleClick(user, cell)
                }}
                onMouseOver={(e) => {
                  if (e.buttons === 1 && index >= 5) handleClick(user, cell)
                }}
                onClick={(e) => {
                  // if (index >= 5) handleClick(user, cell)
                }}
              >
                &nbsp;
              </TableCell>
            ))}
          </TableRow>
        ))}
    </TableBody>
  )
}

export default Body
