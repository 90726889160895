import { onAuthStateChanged } from 'firebase/auth'
import { onSnapshot, doc, CollectionReference, collection } from 'firebase/firestore'
import { createContext, useContext, useState, useEffect } from 'react'
import { auth, authFirestore } from './firebase'
import { User, UserNS } from '../services/user'
import { ScheduleData } from './ScheduleData'

const AuthContext = createContext<{currentUser?: User}>({})
export const useAuth = () => useContext(AuthContext)

export function AuthProvider({ children }: { children: React.ReactNode}) {

	const [currentUser, setCurrentUser] = useState<User | undefined>()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		return onAuthStateChanged(auth, (authUser) => {
			if (authUser) {
				return UserNS.get(authUser?.uid, user => {
					setCurrentUser(user)
					setLoading(false)
				})
			} else {
				setLoading(false)
			}
		})
	}, [])

	const value = {
		currentUser,
	}

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	)
}