import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import { Button } from '@mui/material'
import { uploadImage } from 'shared/utils'
import { ptBR } from 'date-fns/locale'
import { format } from 'date-fns'
import { getDownloadURL } from 'firebase/storage'
import { Post, PostNS } from 'services/posts'

export default function Edit() {
	const { id } = useParams()
	const [post, setPost] = useState<Post>()
	const [fileURL, setFileURL] = useState('')
	const [processing, setProcessing] = useState(false)

	useEffect(() => PostNS.get(id!, post => {
		setPost(post)
		setFileURL(post.imageURL)
	}), [id])

	const handleUpload: React.ChangeEventHandler<HTMLInputElement> = e => {
		if (!e.currentTarget.files) return
		const file = e.currentTarget.files[0]
		uploadImage(file, `${file.name}_${format(new Date(), 'PPpp', { locale: ptBR })}`)
			.then(snapshot => getDownloadURL(snapshot.ref))
			.then(url => setFileURL(url))
	}

	const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = e => {
		if (!post) return
		setProcessing(true)
		PostNS.update(id!, {
			content: post.content,
			title: post.title,
		}).then(() => {
			console.log('done')
			setProcessing(false)
			alert('O seu comunicado foi atualizado com sucesso!')
		})
	}
	return (
		<div className='w-full'>
			{post &&
			<>
				<div className='text-lg'>
					Editar Comunicado
				</div>
				<div className='mt-4'>
					<TextField
						className='w-full'
						label='Titulo'
						value={post.title}
						onChange={e => setPost({ ...post, title: e.currentTarget.value })}/>
				</div>
				<div className='mt-4'>
					<TextField
						className='w-full'
						label='Comunicado'
						multiline
						rows={4}
						value={post.content}
						onChange={e => setPost({ ...post, content: e.currentTarget.value })}
					/>
				</div>
				<div className='mt-4'>
					<Button
						variant='contained'
						component='label'
					>
						Carregar imagem
						<input
							type='file'
							onChange={handleUpload}
							hidden
						/>
					</Button>
				</div>
				<div className='mt-4'>
					{fileURL &&
						<img src={fileURL}/>
					}
				</div>
				<div className='mt-4'>
					<Button
						className='bg-iest-primary text-white text-center no-underline align-middle py-1 px-3 rounded-md'
						variant='contained'
						disabled={processing}
						onClick={handleSubmit}
					>
						Atualizar
					</Button>

				</div>
			</>
			}
		</div>
	)
}
