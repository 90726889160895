import { lastDayOfMonth, startOfDay, startOfMonth } from 'date-fns'
import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentSnapshot, onSnapshot, PartialWithFieldValue, query, setDoc, Timestamp, where } from 'firebase/firestore'
import { firestore } from 'shared/firebase'
import { Client, ClientNS, clientsCollection } from './clients'
import { User, UserNS } from './user'

export interface FirestoreTimelog {
	clientID: string
	end: Timestamp
	ownerID: string
	start: Timestamp
}
export interface Timelog {
	id: string
	client: Client
	start: Date
	end: Date
	owner: User
}

export interface TimelogData {
	id: string
	date: Date
	owner: User
	client: Client
	quantity: number
}


export const timelogCollection = collection(firestore, 'calendarEvents') as CollectionReference<FirestoreTimelog>

async function fromFirestoreData(docSnap: DocumentSnapshot<FirestoreTimelog>): Promise<TimelogData> {
	const data = docSnap.data()

	if (data) {

		const client = await ClientNS.getSync(data.clientID)
		const owner = await UserNS.getSync(data.ownerID)
		const quantity = (data.end.toDate().getTime() - data.start.toDate().getTime()) / 1000 / 60 / 60

		return {
			id: docSnap.id,
			client: client,
			owner: owner,
			date: startOfDay(data.start.toDate()),
			quantity,
		}
	} else {
		throw new Error('Can\'t find id')
	}
}

async function fromFirestore(docSnap: DocumentSnapshot<FirestoreTimelog>): Promise<Timelog> {
	const data = docSnap.data()
	if (data) {

		const client = await ClientNS.getSync(data.clientID)
		const owner = await UserNS.getSync(data.ownerID)

		return {
			id: docSnap.id,
			client: client,
			owner: owner,
			start: data.start.toDate(),
			end: data.end.toDate(),
		}
	} else {
		throw new Error('Can\'t find id')
	}
}


function toTimestamp(date?: Date): Timestamp | undefined {
	return date ? Timestamp.fromDate(date) : undefined
}

function toFirestore(timelog: Partial<Timelog>): PartialWithFieldValue<FirestoreTimelog> {
	return {
		...timelog.client && { clientID: timelog.client.id },
		...timelog.owner && { ownerID: timelog.owner.id },
		...timelog.start && { start: toTimestamp(timelog.start) },
		...timelog.end && { end: toTimestamp(timelog.end) },
	}
}

export namespace TimelogNS {
    export function create(values: Partial<Timelog>) {
    	return addDoc(timelogCollection, toFirestore(values))
    }
	export function list(id: string | undefined, cb: (data: Timelog[]) => void) {
		if (!id) {
			alert('Erro ao visualizar, favor contatar o TI')
			throw new Error()
		}
		return onSnapshot(query(timelogCollection, where('ownerID', '==', id)), snapshot => {
    		Promise.all(snapshot.docs.map(docSnap => fromFirestore(docSnap)))
				.then(data => cb(data))
		})
	}
    export function listData(refDate: Date, cb: (data: TimelogData[]) => void) {
    	const q = query(
    		timelogCollection,
    		where('start', '>=', startOfMonth(refDate)),
    		where('start', '<', lastDayOfMonth(refDate)),
    	)
    	return onSnapshot(q, snapshot => {
    		Promise.all(snapshot.docs.map(docSnap => fromFirestoreData(docSnap)))
    			.then(data => {
    				cb(data.reduce<TimelogData[]>((acc, nextRow) => {

    					const aux = acc.find(row => {
    						return row.owner.id === nextRow.owner.id
                                && row.client.id === nextRow.client.id
                                && row.date.getTime() === nextRow.date.getTime()
    					})
    					if (!aux) {
    						acc.push(nextRow)
    						return [...acc]
    					} else {
    						const index = acc.indexOf(aux)
    						acc[index].quantity += nextRow.quantity
    						return [...acc]
    					}
    				}, []))
    			})
    	})
    }
    export function update(id: string, data: Partial<Timelog>) {
    	return setDoc(doc(timelogCollection, id), toFirestore(data), { merge: true })
    }
	export function remove(id: string) {
		return deleteDoc(doc(timelogCollection, id))
	}
}