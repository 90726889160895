import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'shared/auth'

const index = () => {
  const { currentUser } = useAuth()

  const hasFullAccess = currentUser?.admin || currentUser?.department === 'TI'
  const canSeeConverter =
    currentUser?.department === 'Fiscal' ||
    currentUser?.department === 'DP' ||
    hasFullAccess
  const canSeeInvoice =
    currentUser?.displayName === 'Tian Bin' ||
    currentUser?.displayName === 'Beatriz Chen' ||
    hasFullAccess
  const canSeeHros = currentUser?.displayName === 'Eliana Lei' || hasFullAccess
  return (
    <ul className='p-10'>
      <li className='rounded-xl shadow-md w-full p-4'>
        <Link to='https://iestgroup.larksuite.com/share/base/form/shrusHv11tyZpJNWHG4W1xzF1eg'>
          <p>Requisição de vaga interna</p>
          <div className='mt-2 mx-2 text-sm text-gray-700'>
            Clique aqui para acessar o link do Lark para fazer o processo de
            solicitação de vaga.
          </div>
        </Link>
      </li>
      {!canSeeConverter && !canSeeInvoice && !canSeeHros && (
        <div>Não há ferramentas disponíveis para o seu departamento.</div>
      )}
      {canSeeConverter && (
        <li className='rounded-xl shadow-md w-full p-4'>
          <Link to='conversor'>
            <p>Conversor RPS</p>
            <div className='mt-2 mx-2 text-sm text-gray-700'>
              Essa ferramenta recebe uma planilha contendo várias notas fiscais
              de acordo com um modelo predeterminado e gera um arquivo txt que
              pode ser enviado para o site da Prefeitura para gerar as Notas
              fiscais eletrônicas.
            </div>
          </Link>
        </li>
      )}

      {canSeeInvoice && (
        <li className='rounded-xl shadow-md w-full p-4'>
          <a href='https://invoice.iestgroup.com'>
            <p>Invoices</p>
            <div className='mt-2 mx-2 text-sm text-gray-700'>
              Essa ferramenta armazena dados de invoice e gera um pdf de acordo.
              (O login é o mesmo do Portal)
            </div>
          </a>
        </li>
      )}

      {canSeeHros && (
        <li className='rounded-xl shadow-md w-full p-4'>
          <a href='https://hros.iestgroup.com/login'>
            <p>HROS</p>
            <div className='mt-2 mx-2 text-sm text-gray-700'>
              Ferramenta de terceirização para geração de invoices, feito pela
              Plastika.
            </div>
          </a>
        </li>
      )}
    </ul>
  )
}

export default index
