import { useState } from 'react'
import { Modal, Button, LinearProgress, MenuItem, TextField as MuiTextField, Box } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { TextField, Select } from 'formik-mui'
import * as Yup from 'yup'
import AddIcon from '@mui/icons-material/Add'
import { UserNS } from 'services/user'

interface EditToolbarProps {
	periods: string[],
    departments: string[],
    moderationTeams: string[],
}

  interface Values {
	displayName: string
	fullName: string
	email: string
	password: string
	period: string
	department: string
	birthday: Date
	moderationTeam: string
}

const EditToolbar = ({ periods, departments, moderationTeams }: EditToolbarProps) => {
	const [show, setShow] = useState(false)

	const handleClick = () => {
		setShow(true)
	}

	const FormSchema = Yup.object().shape({
		displayName: Yup.string()
			.required('Este campo é obrigatório.'),
		fullName: Yup.string()
			.required('Este campo é obrigatório.'),
		email: Yup.string().email('E-mail inválido, preencha corretamente.').required('Este campo é obrigatório.'),
		password: Yup.string()
			.min(6, 'A senha precisa ter no mínimo 6 digitos.')
			.required('Este campo é obrigatório.'),
		period: Yup.string()
			.required('Este campo é obrigatório.'),
		department: Yup.string()
			.required('Este campo é obrigatório.'),
	})

	return (
		<GridToolbarContainer>
			<Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
                Adicionar
			</Button>
			<Modal
				open={show}
				onClose={() => setShow(false)}
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
					}}
				>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
						<Formik
							initialValues={{
								displayName: '',
								fullName: '',
								email: '',
								password: '',
								period: '',
								department: '',
								moderationTeam: ' ',
								birthday: new Date(),
							}}
							validationSchema={FormSchema}
							onSubmit={(values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
								UserNS.create({
									displayName: values.displayName,
									fullName: values.fullName,
									email: values.email,
									password: values.password,
									period: values.period,
									birthday: new Date(values.birthday),
									moderationTeam: values.moderationTeam,
									department: values.department,
								})
								.then(() => {
									setShow(false)
									alert('Usuário criado com sucesso.')
								})
								.catch((err) => {
									alert('Erro ao criar o usuário ' + err)
								})
								.finally(() => {
									resetForm()
								})
							}}
						>
							{({ submitForm, isSubmitting, values, setFieldValue, touched, errors }) => (
								<Form className='mx-auto max-w-lg flex flex-col space-y-4'>
									<div className='text-center text-xl font-bold'>Adicionar Usuário</div>
									<Field
										component={TextField}
										name='displayName'
										type='text'
										label='Nome'
										required
									/>
									<Field
										component={TextField}
										name='fullName'
										type='text'
										label='Nome Completo'
										required
									/>
									<Field
										component={TextField}
										type='email'
										label='Email'
										name='email'
										required
									/>
									<Field
										component={TextField}
										type='text'
										label='Senha'
										name='password'
										required
									/>
									<Field
										component={Select}
										type='text'
										label='Periodo'
										name='period'
										defaultValue=''
										required
									>
										{periods.map(period => (

											<MenuItem key={period} value={period}>{period}</MenuItem>
										))}
									</Field>
									<Field
										component={Select}
										type='text'
										label='Departamento'
										name='department'
										defaultValue=''
										required
									>
										{departments.map(department => (

											<MenuItem key={department} value={department}>{department}</MenuItem>
										))}
									</Field>
									{ values.department === 'Moderação de Conteúdo' && <Field
										component={Select}
										type='text'
										label='Equipe de moderação'
										name='moderationTeam'
										defaultValue=''
									>
										{moderationTeams.map(team => (

											<MenuItem key={team} value={team}>{team}</MenuItem>
										))}
									</Field>}

									<DatePicker
										value={values.birthday}
										onChange={newValue => setFieldValue('birthday', newValue)}
										renderInput={(params) => (
											<MuiTextField {...params}/>
										)}
										label='Aniversário'
									/>
									{isSubmitting && <LinearProgress />}

									<Button
										variant='contained'
										color='primary'
										disabled={isSubmitting}
										onClick={submitForm}
									>
									Adicionar
									</Button>
								</Form>
							)}
						</Formik>

					</LocalizationProvider>
				</Box>
			</Modal>
		</GridToolbarContainer>
	)
}

export default EditToolbar