import { AuthProvider } from 'shared/auth'
import AppRoutes from './AppRoutes'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { createTheme, ThemeProvider } from '@mui/material'
import { ptBR } from 'date-fns/locale'
import { ptBR as dataPtBR } from '@mui/x-data-grid'

const theme = createTheme({
	palette: {
		primary: {
			main: '#003366',
		},
	},
	typography: {
		fontFamily: '"Poppins","Roboto","Arial",sans-serif',
		h4: {
			fontWeight: '600',
			color: '#202020',
		},
		h5: {
			fontWeight: '600',
			color: '#202020',
		},
		h6: {
			color: '#909090',
		},
	},
}, dataPtBR)

const App = () => (
	<ThemeProvider theme={theme}>
		<AuthProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
				<AppRoutes />
			</LocalizationProvider>
		</AuthProvider>
	</ThemeProvider>
)

export default App