import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentSnapshot, getDoc, onSnapshot, PartialWithFieldValue, query, setDoc, Timestamp } from 'firebase/firestore'
import { firestore } from 'shared/firebase'


export interface FirestoreClient {
	name: string
	nickname: string
}
export interface Client {
    id: string
    name: string
    nickname: string
}

export const clientsCollection = collection(firestore, 'clients') as CollectionReference<FirestoreClient>

function fromFirestore(docSnap: DocumentSnapshot<FirestoreClient>): Client {
	const data = docSnap.data()
	if (data) {
		return {
			...data,
			id: docSnap.id,
		}
	} else {
		throw new Error('Can\'t find id')
	}
}

function toFirestore(post: Partial<Client>): PartialWithFieldValue<FirestoreClient> {
	return { ...post }
}

export namespace ClientNS {
    export function create(values: Partial<Client>) {
    	return addDoc(clientsCollection, toFirestore(values))
    }

    export async function getSync(id: string) {
    	const docSnap = await getDoc(doc(clientsCollection, id))
    	return fromFirestore(docSnap)
    }

    export function get(id: string, cb: (data: Client) => void) {
    	return onSnapshot(doc(clientsCollection, id), docSnap => {
    		cb(fromFirestore(docSnap))
    	})
    }
    export function list(cb: (data: Client[]) => void) {
    	return onSnapshot(query(clientsCollection), snapshot => {
    		cb(snapshot.docs.map(docSnap => fromFirestore(docSnap)))
    	})
    }
    export function update(id: string, data: Partial<Client>) {
    	return setDoc(doc(clientsCollection, id), toFirestore(data), { merge: true })
    }
	export function remove(id: string) {
		return deleteDoc(doc(clientsCollection, id))
	}
}